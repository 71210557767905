/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import uniqBy from "lodash/uniqBy";
import { Reducer } from "redux";
import { CardDetails } from "src/components/Card/types";
import { taxpayerIdFormat } from "src/utils/helper";
import { handleData } from "../middlewares/handleData";
import Types from "../types/onboarding";

const initialState = {
  candidateQuestions: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  companyList: {
    loading: false,
    error: "",
    message: "",
    data: [],
  },
  getBridgeToken: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  voluntaryInformation: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  disabilityInformation: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  veteranStatus: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  preEmploymentQuestions: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  emailList: {
    loading: false,
    error: "",
    message: "",
    data: [],
  },
  submitApplication: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  updateEmail: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  providerList: {
    loading: false,
    error: "",
    message: "",
    data: [],
  },
  cards: {
    loading: false,
    error: "",
    message: "",
    data: [],
  },
  irsData: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  getPdf: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  postIrsSign: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
  postIrsSignData: {
    loading: false,
    error: "",
    message: "",
    data: {},
  },
};

const OnboardingReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.POST_CANDIDATE_QUESTIONS_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          candidateQuestions: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            candidateQuestions: {
              loading: false,
              data: {
                ...(state?.candidateQuestions.data || {}),
                ...(payload?.data || {}),
              },
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          candidateQuestions: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.GET_COMPANY_LIST:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          companyList: {
            ...prevState.companyList,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            companyList: {
              loading: false,
              data: payload.data.companies || [],
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          companyList: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.GET_BRIDGE_TOKEN:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          getBridgeToken: {
            ...prevState.getBridgeToken,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            getBridgeToken: {
              loading: false,
              data: payload.data || {},
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          getBridgeToken: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.POST_VOLUNTARY_INFORMATION_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          voluntaryInformation: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          voluntaryInformation: {
            loading: false,
            data: {
              ...(state?.voluntaryInformation.data || {}),
              ...(payload?.data || {}),
            },
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          voluntaryInformation: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.POST_DISABILITY_FORM_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          disabilityInformation: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            disabilityInformation: {
              loading: false,
              data: {
                ...(state?.disabilityInformation.data || {}),
                ...(payload?.data || {}),
              },
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          disabilityInformation: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.POST_VETERAN_STATUS_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          veteranStatus: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          veteranStatus: {
            loading: false,
            data: {
              ...(state?.veteranStatus.data || {}),
              ...(payload?.data || {}),
            },
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          veteranStatus: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.POST_PRE_EMPLOYMENT_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          preEmploymentQuestions: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          preEmploymentQuestions: {
            loading: false,
            data: {
              ...(state?.preEmploymentQuestions.data || {}),
              ...(payload?.data || {}),
            },
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          preEmploymentQuestions: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.GET_EMAIL_LIST:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          emailList: {
            ...prevState.emailList,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          emailList: {
            loading: false,
            data: payload.data.emails || [],
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          emailList: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.SUBMIT_CANDIDATE_APPLICATION:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          submitApplication: {
            ...prevState.submitApplication,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          submitApplication: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          submitApplication: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.UPDATE_CANDIDATE_EMAIL:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          updateEmail: {
            ...prevState.updateEmail,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateEmail: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateEmail: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.GET_PROVIDER_LIST:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          providerList: {
            ...prevState.providerList,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            providerList: {
              loading: false,
              data: payload.data.providers || [],
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          providerList: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.UPDATE_CARDS:
      return handleData(state, action, {
        request: (prevState: any) => {
          const updatedCards = (state.cards?.data || []).map(
            (card: CardDetails) => {
              if (card.tv_id === payload.tv_id) {
                return {
                  ...card,
                  tv_name: payload.company_name,
                };
              }
              return card;
            },
          );
          return {
            ...prevState,
            cards: {
              data: uniqBy(updatedCards, "tv_name"),
              loading: true,
            },
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            cards: {
              loading: false,
              data: uniqBy(
                [...(payload.data.items || []), ...state.cards.data],
                "tv_name",
              ),
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          cards: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.ADD_CARD:
      return {
        ...state,
        cards: {
          ...state.cards,
          data: [...state.cards.data, payload.card],
        },
      };

    case Types.REMOVE_CARD: {
      const filteredCards = state.cards.data.filter(
        (card: CardDetails) => card.tv_id !== payload.id,
      );

      return {
        ...state,
        cards: {
          ...state.cards,
          data: filteredCards,
        },
      };
    }
    case Types.POST_IRS_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          irsData: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            irsData: {
              loading: false,
              data: {
                ...(payload?.data || {}),
                ...(state?.irsData.data || {}),
              },
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          irsData: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.GET_IRS_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          irsData: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => {
          const irsFormData = payload?.data.irs;
          irsFormData.taxpayer_id = irsFormData?.taxpayer_id
            ? taxpayerIdFormat(irsFormData.taxpayer_id)
            : "";
          irsFormData.spouse_taxpayer_id = irsFormData?.spouse_taxpayer_id
            ? taxpayerIdFormat(irsFormData.spouse_taxpayer_id)
            : "";
          return {
            ...prevState,
            irsData: {
              loading: false,
              data: {
                ...(irsFormData || {}),
                ...(state?.irsData.data?.irs || {}),
              },
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          irsData: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.GET_PDF:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          getPdf: {
            ...prevState.getPdf,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          getPdf: {
            loading: false,
            data: payload || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          getPdf: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.POST_IRS_SIGN:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          postIrsSign: {
            ...prevState.postIrsSign,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          postIrsSign: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          postIrsSign: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    case Types.POST_IRS_SIGN_DATA:
      return handleData(state, action, {
        request: (prevState: any) => ({
          ...prevState,
          postIrsSignData: {
            data: { ...(payload || {}) },
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          postIrsSignData: {
            loading: false,
            data: {
              ...(state?.postIrsSignData.data || {}),
              ...(payload?.data || {}),
            },
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          postIrsSignData: {
            loading: false,
            error: payload?.data?.message,
          },
        }),
      });
    default:
      return state;
  }
};

export default OnboardingReducer;
