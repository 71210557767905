import cs from "classnames";
import { useLocation, useNavigate } from "react-router";
import BasicInfo from "./BasicInfo";
import Experience from "./Experience";
import Forms from "./Forms/Forms";
import classes from "./Onboarding.module.scss";
import PreviewFile from "./PreviewFile";
import Submit from "./Submit";
import Success from "./Success";
import UpdateEmail from "./UpdateEmail";
import OnboardingHeader from "./components/OnboardingHeader/OnboardingHeader";
const Onboarding = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const onboardingStep = () => {
    switch (pathname) {
      case "/onboarding/basic-info":
        return {
          step: 1,
          title: "Basic Info",
          component: (
            <BasicInfo
              buttonText="Next"
              handleNext={() => {
                navigate("/onboarding/experience");
              }}
            />
          ),
        };
      case "/onboarding/experience":
        return {
          step: 2,
          title: "Job Verification",
          component: (
            <Experience
              buttonText="Next"
              handleNext={() => {
                navigate("/onboarding/authorize-irs-verification");
              }}
            />
          ),
        };
      case "/onboarding/authorize-irs-verification":
        return {
          step: 2,
          title: "Authorize IRS Verification",
          component: (
            <PreviewFile
              buttonText="Sign Form"
              handleNext={() => {
                navigate("/onboarding/forms/1");
              }}
            />
          ),
        };
      case "/onboarding/forms":
      case "/onboarding/forms/1":
      case "/onboarding/forms/2":
      case "/onboarding/forms/3":
      case "/onboarding/forms/4":
      case "/onboarding/forms/5":
      case "/onboarding/forms/6":
        return {
          step: 3,
          title: "Forms",
          component: (
            <Forms
              buttonText="Next"
              handleNext={() => {
                navigate("/onboarding/submit");
              }}
            />
          ),
        };
      case "/onboarding/submit":
        return {
          step: 4,
          title: "Submit",
          component: (
            <Submit
              buttonText="Done"
              handleNext={() => {
                navigate("/onboarding/success");
              }}
            />
          ),
        };
      case "/onboarding/update-email":
        return {
          step: 0,
          title: "Update Email",
          component: (
            <UpdateEmail
              handleNext={() => {
                navigate("/onboarding/submit");
              }}
            />
          ),
        };
      case "/onboarding/success":
        return {
          step: 0,
          title: "Success",
          component: (
            <Success
              handleNext={() => {
                window.location.href = "/jobs/1";
              }}
            />
          ),
        };
      default:
        return {
          step: 1,
          title: "Basic Info",
          component: (
            <BasicInfo
              buttonText="Next"
              handleNext={() => {
                navigate("/onboarding/experience");
              }}
            />
          ),
        };
    }
  };

  const Component = onboardingStep().component;

  return (
    <div
      className={cs(classes.container, {
        [classes.intro]: onboardingStep().step === 0,
      })}
    >
      {onboardingStep().step > 0 && (
        <OnboardingHeader
          step={onboardingStep().step}
          title={onboardingStep().title}
          skip={pathname === "/onboarding/authorize-irs-verification"}
        />
      )}
      {Component}
    </div>
  );
};

export default Onboarding;
