/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import Button from "src/components/Button";
import { CardType } from "src/components/Card/types";
import Input from "src/components/Input";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import useData from "src/utils/useData";
import classes from "./JobDetails.module.scss";
// eslint-disable-next-line no-restricted-imports
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  handleType: (type: CardType) => void;
  cardId: string;
  handleManually: () => void;
};

type FormProps = {
  companyName: string;
  jobTitle: string;
  startDate: string;
  endDate: string;
};
const JobDetailsForm: React.FC<Props> = ({
  handleType,
  cardId,
  handleManually,
}) => {
  const { postCompanyManually } = useActions();
  const jobDetailSchema = Yup.object().shape({
    companyName: Yup.string().required("Company name is required").trim(),
    jobTitle: Yup.string().required("Job title is required").trim(),
    startDate: Yup.date().required("Select a date").typeError("Invalid Date"),
    endDate: Yup.date().typeError("Invalid Date"),
  });
  const formattedDate = (data: string) => {
    const date = new Date(data);
    return date.toISOString().split("T")[0];
  };

  const {
    onboarding: {
      cards: { loading },
    },
  } = useData();

  return (
    <div className={classes.formWrapper}>
      <div className={classes.title}>
        <Text>Let’s manually add info for your job</Text>
      </div>
      <div className={classes.form}>
        <Formik
          initialValues={{
            companyName: "",
            jobTitle: "",
            startDate: "",
            endDate: "",
          }}
          validationSchema={jobDetailSchema}
          onSubmit={async (values: FormProps) => {
            const resp: any = await postCompanyManually({
              company_name: values.companyName,
              job_title: values.jobTitle,
              start_date: formattedDate(values.startDate).toString(),
              end_date:
                values.endDate && formattedDate(values.endDate).toString(),
              tv_id: cardId,
            });
            if (resp?.success) {
              handleType("company");
              handleManually();
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            isValid,
            dirty,
            errors,
          }: FormikProps<FormProps>) => {
            return (
              <Form>
                <div className={classes.inputWrapper}>
                  <Input
                    onChange={handleChange}
                    label="Company Name"
                    name="companyName"
                    id="companyName"
                    onBlur={handleBlur}
                    placeholder="Company Name"
                    value={values.companyName}
                    touched={touched.companyName}
                    error={errors.companyName}
                  />
                  <Input
                    onChange={handleChange}
                    label="Job Title"
                    name="jobTitle"
                    id="jobTitle"
                    onBlur={handleBlur}
                    placeholder="Job Title"
                    value={values.jobTitle}
                    touched={touched.jobTitle}
                    error={errors.jobTitle}
                  />
                  <div className={classes.datePickerWrapper}>
                    <label className={classes.datePickerLabel}>
                      Start Date
                    </label>
                    <Field name="startDate">
                      {({ field, form }: FieldProps) => (
                        <DatePicker
                          autoComplete="off"
                          placeholderText="Select start date"
                          className={classes.datePicker}
                          selected={field.value}
                          dateFormat="MMM dd, yyyy"
                          onBlur={handleBlur}
                          maxDate={new Date(values.endDate)}
                          selectsStart
                          name="startDate"
                          value={field.value}
                          startDate={field.value}
                          endDate={form.values.endDate}
                          onChange={(date) =>
                            form.setFieldValue("startDate", date)
                          }
                        />
                      )}
                    </Field>
                    {touched.startDate && errors.startDate && (
                      <div className={classes.invalidFeedback}>
                        Select start date
                      </div>
                    )}
                  </div>
                  <div className={classes.datePickerWrapper}>
                    <label className={classes.datePickerLabel}>End Date</label>
                    <Field name="endDate">
                      {({ field, form }: FieldProps) => (
                        <DatePicker
                          autoComplete="off"
                          placeholderText="Select end date"
                          className={classes.datePicker}
                          selected={field.value}
                          dateFormat="MMM dd, yyyy"
                          onBlur={handleBlur}
                          onChange={(date) => {
                            form.setFieldValue("endDate", date);
                          }}
                          selectsEnd
                          name="endDate"
                          startDate={form.values.startDate}
                          endDate={field.value}
                          minDate={form.values.startDate}
                        />
                      )}
                    </Field>
                    {touched.endDate && errors.endDate && (
                      <div className={classes.invalidFeedback}>
                        Select end date
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || loading}
                  >
                    {isValid && dirty ? "Save Details" : "Add"}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default JobDetailsForm;
