import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Logo from "src/components/Logo";
import Text from "src/components/Text/Text";
import classes from "./JobDesc.module.scss";

const JobDesc = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <Logo />
      <div className={classes.title}>
        <Text fontWeight="medium" size={20}>
          Wireless Customer Service Representative
        </Text>
      </div>
      <div className={classes.details}>
        <Text>
          Locations : <span>Tulsa, OK</span>
        </Text>
        <Text>
          Categories : <span> Customer Service</span>
        </Text>
        <Text>
          Req ID : <span>81000 </span>
        </Text>
      </div>
      <div className={classes.descriptionContainer}>
        <Text className={classes.descTitle}>Job Description</Text>
        <Text className={classes.descSubTitle}>Department Summary</Text>
        <Text className={classes.description}>
          <Text>
            DISH and its family of brands is a Fortune 200 company with more
            than $15 billion in annual revenue that continues to redefine the
            communications industry. Our legacy is innovation and a willingness
            to challenge the status quo, including reinventing ourselves. We
            disrupted the pay-TV industry in the mid-90s with the launch of the
            DISH satellite TV service, taking on some of the largest U.S.
            corporations in the process, and grew to be the fourth-largest
            pay-TV provider. At DISH, we reimagine connectivity through new
            platforms, new business models and new ways of thinking.
          </Text>
          <br />
          <Text>
            Now we have our sights set on upending the wireless industry and
            unseating the entrenched incumbent carriers. We are building
            America's first cloud-native 5G network to transform the way we
            live, work and play with unlimited potential. Our teams operate at
            the intersection of wireless, TV service, taking on some of the
            largest U.S. corporations in the process, and grew to be the
            fourth-largest pay-TV provider. At DISH, we reimagine connectivity
            through new platforms, new business models and new ways of thinking.
            DISH and its family of brands is a Fortune 200 company with more
            than $15 billion in annual revenue that continues to redefine the
            communications industry. Our legacy is innovation and a willingness
            to challenge the status quo, including reinventing ourselves. We
            disrupted the pay-TV industry in the mid-90s with the launch of the
            DISH satellite TV service, taking on some of the largest U.S.
            corporations in the process, and grew to be the fourth-largest
            pay-TV provider. At DISH, we reimagine connectivity through new
            platforms, new business models and new ways of thinking.
          </Text>
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        <Button onClick={() => navigate("/intro")}>Apply</Button>
      </div>
    </div>
  );
};

export default JobDesc;
