/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import ErrorMessage from "src/components/ErrorMessage";
import FullPageLoader from "src/components/FullPageLoader";
import Input from "src/components/Input";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { phoneRegExp } from "src/utils/helper";
import useData from "src/utils/useData";
import classes from "./Submit.module.scss";

type Props = {
  handleNext: () => void;
  buttonText: string;
};
type FormProps = {
  receive_updates: boolean;
  updates_phone: string;
};
const Submit: React.FC<Props> = ({ buttonText, handleNext }) => {
  const { getEmailList, submitApplicationData } = useActions();
  const navigate = useNavigate();
  const {
    onboarding: {
      emailList: { data: emails, loading: listLoading },
      submitApplication: { error, loading: submitLoading },
    },
  } = useData();
  const selectedEmail = emails[emails.length - 1];
  useEffect(() => {
    getEmailList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSchema = Yup.object().shape({
    receive_updates: Yup.boolean(),
    updates_phone: Yup.string().when("receive_updates", {
      is: true,
      then: () =>
        Yup.string()
          .label("Phone number")
          .required()
          .matches(phoneRegExp, "Enter valid phone number")
          .min(10, "Enter valid phone number")
          .max(10, "Enter valid phone number")
          .trim(),
      otherwise: () => Yup.string().nullable(),
    }),
  });
  return (
    <div className={classes.submitWrapper}>
      {submitLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          receive_updates: false,
          updates_phone: "",
        }}
        validationSchema={formSchema}
        onSubmit={async (values) => {
          const resp: any = await submitApplicationData(
            values.receive_updates
              ? {
                  ...values,
                  email: selectedEmail,
                  updates_phone: values.updates_phone.toString(),
                }
              : { email: selectedEmail },
          );
          if (resp?.success) handleNext();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          isValid,
          errors,
        }: FormikProps<FormProps>) => {
          return (
            <>
              <Form>
                <div>
                  <Text className={classes.title}>
                    Here’s what we have for your email address. Please select
                    your primary email:
                  </Text>
                  <div
                    className={cn(classes.emailWrapper, {
                      [classes.loading]: listLoading,
                    })}
                  >
                    {listLoading ? (
                      <div className={classes.spinner} />
                    ) : (
                      <Text fontWeight="bold">{selectedEmail}</Text>
                    )}
                  </div>
                  <Text className={classes.text}>
                    Email not correct?
                    <span
                      onClick={() => navigate("/onboarding/update-email")}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    >
                      Update
                    </span>
                  </Text>
                  <div className={classes.mobileOption}>
                    <CheckBox
                      id="receive_updates"
                      checked={values.receive_updates}
                      name="receive_updates"
                      error={errors.receive_updates}
                      touched={touched.receive_updates}
                      onChange={handleChange}
                      label="Would you like to receive text updates?"
                      wrapperClassName={classes.checkboxWrapper}
                    />
                    {values?.receive_updates && (
                      <Input
                        label="Enter Phone Number"
                        pattern="\d+"
                        type="number"
                        required={true}
                        placeholder="Enter Phone Number"
                        error={errors.updates_phone}
                        touched={touched.updates_phone}
                        value={values.updates_phone}
                        id="updates_phone"
                        name="updates_phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        maxLength={10}
                      />
                    )}
                  </div>
                  <div className={classes.verifiedWrapper}>
                    <Badge type="success">You’re all verified</Badge>
                    <div>
                      <Text className={classes.verifiedPara}>
                        You’re all verified! This will help your candidacy move
                        through the application process quicker. We’ll be in
                        touch if you’ve been selected for an interview
                      </Text>
                    </div>
                  </div>
                </div>
              </Form>
              {error && <ErrorMessage errorMessage={error} />}
              <Button
                type="submit"
                disabled={!isValid || submitLoading}
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                {buttonText}
              </Button>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Submit;
