import cx from "classnames";
import React, { useState } from "react";
import closedEye from "src/assets/icons/eye-closed-icon.svg";
import openEye from "src/assets/icons/eye-icon.svg";
import classes from "./Input.module.scss";
import { Props } from "./types";

const Input: React.FC<Props> = (Props) => {
  const {
    isAutoComplete,
    inputClassName = "",
    inputGroupClassName = "",
    inputWrapperClassName = "",
    loading = false,
    leftIcon,
    rightIcon,
    iconClassName = "",
    disabled = false,
    focused = false,
    error = "",
    label,
    labelClassName = "",
    inputId,
    touched = false,
    required = false,
    showPasswordIcon = false,
    placeholder,
    id,
    ...rest
  } = Props;

  const [inputType, setInputType] = useState(rest.type);

  const renderIcon = (icon: React.ReactNode) => {
    if (!icon) return;
    return (
      <span
        className={cx(classes.iconStyling, {
          [iconClassName]: iconClassName,
          [classes.leftIcon]: !!leftIcon,
          [classes.rightIcon]: !!rightIcon,
        })}
      >
        {icon}
      </span>
    );
  };

  const renderPasswordEyeIcon = () => {
    return (
      <div
        onClick={() => {
          if (inputType === "text") {
            setInputType("password");
          } else {
            setInputType("text");
          }
        }}
        className={cx(classes.iconStyling, classes.passwordIcon, {
          [iconClassName]: iconClassName,
        })}
      >
        {inputType === "text" ? (
          <img className={classes.eyeImage} src={openEye} alt="eye-open" />
        ) : (
          <img className={classes.eyeImage} src={closedEye} alt="eye-closed" />
        )}
      </div>
    );
  };

  const renderLabel = () => {
    if (!label) return;
    return (
      <label
        className={cx(classes.label, {
          [labelClassName]: labelClassName,
        })}
        htmlFor={id}
      >
        {label}
        {required && <span className={classes.requiredLabel}>*</span>}
      </label>
    );
  };

  if (!leftIcon && !rightIcon) {
    return (
      <div
        className={cx(classes.wrapper, {
          [inputWrapperClassName]: inputWrapperClassName,
        })}
      >
        {renderLabel()}
        <div
          className={cx(classes.formGroupStyling, {
            [inputGroupClassName]: inputGroupClassName,
            [classes.focused]: focused,
            [classes.isInvalid]: touched && error,
          })}
        >
          <input
            {...(inputId ? { id: inputId } : {})}
            className={cx(classes.inputStyling, {
              [inputClassName]: inputClassName,
            })}
            placeholder={placeholder}
            disabled={disabled || loading}
            autoComplete={isAutoComplete ? isAutoComplete : "off"}
            type={inputType}
            {...rest}
          />
          {showPasswordIcon && renderPasswordEyeIcon()}
        </div>
        {touched && error && (
          <div className={classes.invalidFeedback}>{error}</div>
        )}
      </div>
    );
  }

  return (
    <div
      className={cx(classes.wrapper, {
        [inputWrapperClassName]: inputWrapperClassName,
      })}
    >
      {renderLabel()}
      <div
        className={cx(classes.formGroupStyling, {
          [inputGroupClassName]: inputGroupClassName,
          [classes.focused]: focused,
          [classes.isInvalid]: touched && error,
        })}
      >
        {leftIcon && renderIcon(leftIcon)}
        <input
          className={cx(classes.inputStyling, {
            ["px-0"]: !!leftIcon,
            [inputClassName]: inputClassName,
          })}
          {...(inputId ? { id: inputId } : {})}
          disabled={disabled || loading}
          placeholder={placeholder}
          {...rest}
        />
        {rightIcon && renderIcon(rightIcon)}
      </div>

      {touched && error && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </div>
  );
};

export default Input;
