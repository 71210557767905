// CustomDialog.js
import React from "react";
import Button from "../Button";
import classes from "./Dialog.module.scss";
type Props = {
  showDialog: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
};
const CustomDialog: React.FC<Props> = ({
  showDialog,
  onClose,
  title,
  description,
}) => {
  if (!showDialog) return;
  return (
    <div className={classes.dialog}>
      <div className={classes.dialogContent}>
        {title && <h2>{title}</h2>}
        <p>{description}</p>
        <Button
          type="button"
          variant="text"
          className={classes.closeBtn}
          onClick={onClose}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CustomDialog;
