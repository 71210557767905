import cn from "classnames";
import React from "react";
import { ArrowDownIcon } from "src/assets/icons";
import classes from "./DropDown.module.scss";
import { DropdownProps } from "./types";

const Dropdown: React.FC<DropdownProps> = ({
  required = false,
  label,
  options,
  onChange,
  value,
  name,
  error,
  touched,
  ...rest
}) => {
  return (
    <div className={classes.dropdown}>
      <div className={classes.label}>
        {label} {required && <span>*</span>}
      </div>
      <select
        onChange={onChange}
        value={value}
        className={cn(classes.select, {
          [classes.isInvalid]: touched && error,
        })}
        name={name}
        {...rest}
      >
        <option disabled value="" hidden>
          Select
        </option>
        {options.map(
          (option: { label: string; value: string }, index: number) => (
            <option className={classes.option} key={index} value={option.value}>
              {option.label}
            </option>
          ),
        )}
      </select>
      <div className={classes.customIcon}>
        <ArrowDownIcon />
      </div>
      {error && touched && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </div>
  );
};

export default Dropdown;
