import cx from "classnames";
import React from "react";
import classes from "./Button.module.scss";
import { Props } from "./types";

const Button: React.FC<Props> = (Props) => {
  const {
    variant = "fill",
    rightIcon,
    leftIcon,
    loading = false,
    disabled = false,
    buttonColor = "primary",
    className = "",
    children,
    ...rest
  } = Props;

  const hasIcons = !!rightIcon || !!leftIcon || loading;

  const renderLeftIcon = () => {
    if (!leftIcon) return;
    return (
      <span className={cx("btn-inner--icon", classes.leftIconStyling)}>
        {leftIcon}
      </span>
    );
  };

  const renderRightIcon = () => {
    if (!rightIcon) return;
    return (
      <span className={cx("btn-inner--icon", classes.rightIconStyling)}>
        {rightIcon}
      </span>
    );
  };

  const renderButton = () => {
    if (!children) return;
    return (
      <span className={cx({ "btn-inner--text": hasIcons })}>
        {children} {loading && <span className={classes.spinner}></span>}
      </span>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading || disabled) {
      event.preventDefault();
      return;
    }
    // Handle the button click event here
  };

  return (
    <button
      className={cx(
        classes.buttonStyling,
        {
          [className]: className,
          "btn-icon": hasIcons,
          [classes.disabled]: disabled || loading,
        },
        classes[`${buttonColor}-${variant}`],
        // classes[size],
      )}
      onClick={handleClick}
      disabled={disabled || loading}
      {...rest}
    >
      {renderLeftIcon()}
      {renderButton()}
      {renderRightIcon()}
    </button>
  );
};

export default Button;
