import { Request } from "./request";

export type LoginUser = { name: string; email: string };
export const doLogin = async (data: LoginUser) => {
  return Request.call({
    url: "api/applicant/step1",
    method: "POST",
    data,
  });
};

export const uploadResume = async (data: FormData, token: string) => {
  return Request.call({
    url: "api/applicant/step1/resume",
    method: "POST",
    headers: {
      contentType: "multipart/form-data",
      Authorization: "Bearer " + token,
    },
    data,
  });
};
