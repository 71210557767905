import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { publicRoutes } from "src/routes";
import { getRoutes } from "src/utils/getRoutes";
import classes from "./PublicLayout.module.scss";

const PublicLayout: React.FC = () => {
  return (
    <div className={classes.container}>
      <Routes>
        {getRoutes(publicRoutes)}
        <Route path="*" element={<Navigate to="/jobs/1" replace />} />
      </Routes>
    </div>
  );
};

export default PublicLayout;
