import cn from "classnames";
import React from "react";
import ModernDrawer from "react-modern-drawer";
import classes from "./Drawer.module.scss";
// eslint-disable-next-line no-restricted-imports
import "react-modern-drawer/dist/index.css";
type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  lockBackgroundScroll?: boolean;
};

const Drawer: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  className = "",
  lockBackgroundScroll,
}) => {
  if (!isOpen) return null;
  return (
    <ModernDrawer
      className={cn(classes.drawer, { [className]: className })}
      open={isOpen}
      onClose={onClose}
      direction="bottom"
      lockBackgroundScroll={lockBackgroundScroll}
    >
      {children}
    </ModernDrawer>
  );
};

export default Drawer;
