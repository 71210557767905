import React from "react";
import { useLocation, useNavigate } from "react-router";
import ScrollToTop from "src/components/ScrollToTop";
import classes from "./Forms.module.scss";
import CandidateQuestions from "./components/CandidateQuestions";
import DepartmentSummary from "./components/DepartmentSummary";
import DisabilityForm from "./components/DisabilityForm/DisabilityForm";
import PreEmployment from "./components/PreEmployement";
import VeteranStatus from "./components/VeteranStatus";
import VoluntaryInformation from "./components/VoluntaryInformation";
type Props = {
  handleNext: () => void;
  buttonText: string;
};

const Forms: React.FC<Props> = ({ handleNext, buttonText }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const renderForm = () => {
    switch (pathname) {
      case "/onboarding/forms/1":
        return {
          component: (
            <CandidateQuestions
              handleNext={() => {
                navigate("/onboarding/forms/2");
              }}
              buttonText={buttonText}
              step={1}
            />
          ),
        };
      case "/onboarding/forms/2":
        return {
          component: (
            <VoluntaryInformation
              handleNext={() => {
                navigate("/onboarding/forms/3");
              }}
              buttonText={buttonText}
              step={2}
            />
          ),
        };
      case "/onboarding/forms/3":
        return {
          component: (
            <DisabilityForm
              handleNext={() => {
                navigate("/onboarding/forms/4");
              }}
              buttonText={buttonText}
              step={3}
            />
          ),
        };
      case "/onboarding/forms/4":
        return {
          component: (
            <VeteranStatus
              handleNext={() => {
                navigate("/onboarding/forms/5");
              }}
              buttonText={buttonText}
              step={4}
            />
          ),
        };
      case "/onboarding/forms/5":
        return {
          component: (
            <PreEmployment
              handleNext={() => {
                navigate("/onboarding/forms/6");
              }}
              buttonText={buttonText}
              step={5}
            />
          ),
        };
      case "/onboarding/forms/6":
        return {
          component: (
            <DepartmentSummary
              handleNext={handleNext}
              buttonText={buttonText}
            />
          ),
        };
      default:
        return {
          component: (
            <CandidateQuestions
              handleNext={() => {
                navigate("/onboarding/forms/2");
              }}
              buttonText={buttonText}
              step={1}
            />
          ),
        };
    }
  };

  const Component = renderForm().component;

  return (
    <div className={classes.formContainer}>
      <ScrollToTop pathname={pathname} />
      {Component}
    </div>
  );
};

export default Forms;
