/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import FullPageLoader from "src/components/FullPageLoader";
import Input from "src/components/Input";
import Logo from "src/components/Logo";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { emailRegex } from "src/utils/helper";
import useData from "src/utils/useData";

import classes from "./UpdateEmail.module.scss";
type Props = {
  handleNext: () => void;
};
type FormProps = { email: string };
const UpdateEmail: React.FC<Props> = ({ handleNext }) => {
  const {
    onboarding: {
      updateEmail: { loading },
    },
  } = useData();
  const { updateCandidateEmail } = useActions();
  const [updateEmail, setUpdateEmail] = useState<boolean>(true);
  return (
    <>
      {loading && <FullPageLoader />}
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .matches(emailRegex, "Enter valid email address")
            .required("Email is required")
            .trim(),
        })}
        onSubmit={async (values: FormProps) => {
          if (updateEmail) {
            updateEmail && setUpdateEmail(false);
          } else {
            const resp: any = await updateCandidateEmail(values.email);
            if (resp?.success) handleNext();
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          dirty,
        }: FormikProps<FormProps>) => {
          return (
            <Form>
              <div className={classes.updateEmailWrapper}>
                <div className={classes.upperWrapper}>
                  <Logo />
                  {updateEmail ? (
                    <div className={classes.wrapper}>
                      <Text className={classes.title}>
                        Let’s get your email updated:
                      </Text>
                      <Input
                        label="Email Address"
                        placeholder="Enter email address"
                        name="email"
                        id="email"
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <Text className={classes.submitText}>
                        Here’s what we have for your email address. Is this
                        correct?
                        <span>If not, tap to update.</span>
                      </Text>
                      <div className={classes.emailWrapper}>
                        <Text
                          className={classes.email}
                          onClick={() => setUpdateEmail(true)}
                        >
                          {values.email}
                        </Text>
                      </div>
                      <div className={classes.verifiedWrapper}>
                        <Badge type="success">You’re all verified</Badge>
                        <div>
                          <Text className={classes.verifiedPara}>
                            You’re all verified! This will help your candidacy
                            move through the application process quicker. We’ll
                            be in touch if you’ve been selected for an interview
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <Button type="submit" disabled={loading || !isValid || !dirty}>
                  {updateEmail ? "Update Email Address" : "Done"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default UpdateEmail;
