import React from "react";
import classes from "./FullPageLoader.module.scss";
const FullPageLoader = () => {
  return (
    <div className={classes.fullScreenLoader}>
      <span className={classes.spinner} />
    </div>
  );
};

export default FullPageLoader;
