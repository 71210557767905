import { AnyAction, Reducer, combineReducers } from "redux";

import { RootState } from "..";
import AuthReducer from "./auth";
import OnboardingReducer from "./onboarding";

const reducers = {
  auth: AuthReducer,
  onboarding: OnboardingReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  return combinedReducer(state, action);
};

export default rootReducer;
