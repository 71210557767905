import React from "react";
import { Navigate, Route, Routes } from "react-router";

import AuthLayout from "./layout/AuthLayout";
import PrivateLayout from "./layout/PrivateLayout";
import PublicLayout from "./layout/PublicLayout";

const App = () => {
  return (
    <Routes>
      <Route path="/private/*" element={<PrivateLayout />} />
      <Route path="/onboarding/*" element={<AuthLayout />} />
      <Route path="/*" element={<PublicLayout />} />
      <Route path="*" element={<Navigate to="/jobs/1" replace />} />
    </Routes>
  );
};

export default App;
