/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import Button from "src/components/Button";
import Dropdown from "src/components/DropDown";
import ErrorMessage from "src/components/ErrorMessage";
import FullPageLoader from "src/components/FullPageLoader";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { preEmploymentOptions } from "src/utils/constants";
import useData from "src/utils/useData";
import classes from "./PreEmployment.module.scss";
type Props = {
  handleNext: () => void;
  buttonText: string;
  step: number;
};
type FormProps = {
  pes_complete: string;
  pes_el_inv: string;
};
let finishLater: boolean;

const PreEmployment: React.FC<Props> = ({ handleNext, buttonText, step }) => {
  const formSchema = Yup.object().shape({
    pes_complete: Yup.string().required("This field is required"),
    pes_el_inv: Yup.string().required("This field is required"),
  });
  const { postPreEmploymentQuestions } = useActions();
  const {
    onboarding: {
      preEmploymentQuestions: { error, loading, data },
    },
  } = useData();

  const { pes_complete, pes_el_inv } = data;

  const handleFinishLater = async (values: FormProps) => {
    //TODO: for empty string of field ("") BackEnd throws error
    Object.keys(values).forEach((key) => {
      if (values[key as "pes_complete" | "pes_el_inv"] === "") {
        delete values[key as "pes_complete" | "pes_el_inv"];
      }
    });
    finishLater = true;
    const resp: any = await postPreEmploymentQuestions(
      {
        ...values,
        finish_later: finishLater,
      },
      step,
    );

    if (resp?.success) handleNext();
    finishLater = false;
  };

  return (
    <div className={classes.wrapper}>
      {loading && <FullPageLoader />}
      <Formik
        initialValues={{
          pes_complete: pes_complete || "",
          pes_el_inv: pes_el_inv || "",
        }}
        validateOnMount
        validationSchema={formSchema}
        onSubmit={async (values: FormProps) => {
          finishLater = false;
          const resp: any = await postPreEmploymentQuestions(
            {
              ...values,
              finish_later: finishLater,
            },
            step,
          );
          if (resp?.success) handleNext();
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          isValid,
          errors,
        }: FormikProps<FormProps>) => {
          return (
            <Form>
              <div className={classes.title}>
                <Text size={24} fontWeight="semibold">
                  An Equal Opportunity Employee basis of race, national origin,
                  gender, veteran status, disability, age, religion, or any
                  other other characteristics protected by federal or applicable
                  state law
                </Text>
                <Text>*required field.</Text>
              </div>
              <div className={classes.details}>
                <Text className={classes.marginBottom} fontWeight="bold">
                  Pre-Employment Statement
                </Text>
                <Text className={classes.marginBottom}>
                  I certify that all information in this employment application
                  is true and complete. I understand that any false information
                  or omission may disqualify me from further consideration for
                  employment and may result in my dismissal if discovered at a
                  later date.
                </Text>
                <Text className={classes.marginBottom}>
                  I understand that this application for subsequent employment
                  does not create a contract of employment nor guarantee
                  employment for any definite period of time. If employed, I
                  understand that I have been hired at the will of the employer
                  and my employment may be terminated at any time, with or
                  without cause and with or without notice.
                </Text>
                <Text className={classes.marginBottom}>
                  I understand that clicking "Yes" in response to "I Accept"
                  below constitutes my electronic signature executed as of the
                  date and time of submission, and that by doing so, I am
                  consenting to the use of electronic means to
                </Text>
                <ol type="i">
                  <li className={classes.marginBottom}> sign this form,</li>
                  <li className={classes.marginBottom}>
                    receive this form appearing above, and
                  </li>
                  <li className={classes.marginBottom}>
                    store the underlying documents. I understand that a paper
                    copy of this form may be made available to me upon request
                    to Human Resources
                  </li>
                </ol>
                <div className={classes.marginBottom}>
                  <Dropdown
                    label="Select"
                    options={preEmploymentOptions}
                    required={true}
                    onBlur={handleBlur}
                    error={errors.pes_complete}
                    touched={touched.pes_complete}
                    value={values.pes_complete}
                    onChange={handleChange}
                    name="pes_complete"
                  />
                </div>

                <Text className={classes.marginBottom}>
                  Should you receive a conditional offer, DISH will have our
                  background vendor, Sterling InfoSystems Inc., obtain consumer
                  reports) and/or investigative consumer reports).
                </Text>
                <Text className={classes.marginBottom}>
                  I understand that clicking "Yes" in response to "I Accept"
                  below constitutes my electronic signature executed as of the
                  date and time of submission, and that by doing so, I am
                  consenting to the use of electronic means to invite Sterling
                  to send me an electronic invitation to initiate this process
                  should I receive a conditional offer.
                </Text>
                <div className={classes.marginBottom}>
                  <Dropdown
                    label="Select"
                    options={preEmploymentOptions}
                    required={true}
                    onBlur={handleBlur}
                    error={errors.pes_el_inv}
                    touched={touched.pes_el_inv}
                    value={values.pes_el_inv}
                    onChange={handleChange}
                    name="pes_el_inv"
                  />
                </div>
              </div>
              {error && <ErrorMessage errorMessage={error} />}
              <div className={classes.buttonContainer}>
                <Button type="submit" disabled={!isValid || loading}>
                  {buttonText}
                </Button>
                <Button
                  variant="text"
                  type="button"
                  className={classes.finishLater}
                  onClick={() => handleFinishLater(values)}
                >
                  Finish Later
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PreEmployment;
