import React from "react";
import Button from "src/components/Button";
import Logo from "src/components/Logo";
import SuccessIcon from "src/components/SuccessIcon/SuccessIcon";
import Text from "src/components/Text";
import classes from "./Success.module.scss";
type Props = {
  handleNext: () => void;
};
const Success: React.FC<Props> = ({ handleNext }) => {
  return (
    <div className={classes.successWrapper}>
      <div className={classes.logoWrapper}>
        <Logo />
      </div>
      <div className={classes.successIconWrapper}>
        <SuccessIcon />
        <Text size={24} color="#000" fontWeight="bold">
          Success!
        </Text>
        <Text>
          Your application has been submitted and will be reviewed shortly.
          We’ll be in touch with information about nexts steps
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        <Button onClick={handleNext}>Back to Home</Button>
      </div>
    </div>
  );
};

export default Success;
