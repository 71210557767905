import cs from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import Logo from "src/components/Logo";
import Text from "src/components/Text/Text";
import classes from "./Intro.module.scss";

const Intro = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const renderChild = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className={classes.processText}>
              <Text className={classes.subText}>
                As you move through the application process, you’ll be asked for
                information related to your employment history. As part of this
                you’ll need to verify each job by signing into any related
                accounts. We use a 3rd party system to verify your employment to
                help provide accurate information to help speed up your
                application.
              </Text>
              <Text className={classes.italicText}>
                Your previous and current employers will not be notified.
              </Text>
            </div>
            <div className={classes.whyGetVerified}>
              <Badge type="success">Why get verified?</Badge>
              <Text className={classes.verifyText} fontWeight="light">
                Verifying your information with EV3 offers several advantages,
                as EV3 is a trusted partner of DISH. This verification process
                expedites your employment approval, demonstrates your readiness
                and accuracy to DISH, streamlines the application process,
                saving you time, and signals your commitment to the position.
              </Text>
            </div>
          </>
        );
      case 2:
        return (
          <div className={classes.auth}>
            <div>
              <Text className={classes.authHeader} fontWeight="medium">
                EV3 Authorization
              </Text>
            </div>
            <div>
              <Text className={classes.summaryHeader} fontWeight="regular">
                Notice and Consent for Collection, Use, and Disclosure of
                Personal Information by Employment Screening Plus, LLC
              </Text>
            </div>
            <div className={classes.summary}>
              <Text fontWeight="light" size={14}>
                In connection with your employment or job application with
                Employment Verification Plus, LLC (“the Company”), Personal
                Information and Sensitive Personal Information may be collected
                and used as appropriate to recruit and evaluate you, to comply
                with applicable laws and regulations, and for other applicable
                business purposes. The Company has contracted with us,
                Employment Screening Plus, LLC (dba EV3 Global) (“EV3,” “We”),
                an employment verification and data analytics company, to
                collect some or all such information. The Company offers this
                option to streamline the application process and deliver
                independently verified results regarding your background and
                employment history. The information obtained will be used by the
                Company when making employment decisions. EV3 may also use this
                information to conduct data research and analytics for the
                development and improvement of EV3 products.
                <br />
                <br />
                To the extent that EV3 may use the services of any third parties
                to perform any of its services, EV3 will execute a written
                agreement binding such third parties to terms and conditions no
                less protective of your rights and reservations than the terms
                of this Notice. EV3 will remain responsible to you for any
                violation of the terms of our agreement(s) with such third
                parties.
                <br />
                <br />
                As used in this Notice, the term “Personal Information” or “PI”
                means any information that (a) identifies or relates to an
                individual who can be identified directly or indirectly from
                that data alone or in combination with other information in
                EV3’s possession or control or that EV3 is likely to have access
                to, or (b) the relevant Privacy and Data Protection Requirements
                otherwise define as protected personal information. Personal
                Information does not include publicly available information from
                government records, or de-identified or aggregated consumer
                information. Under certain state laws, PI includes a defined
                subset known as “Sensitive Personal Information” or “SPI,” such
                as Social Security Numbers and other confidential data, that
                requires greater security protections and standards of care in
                handling.
                <br />
                <br />
                EV3 may collect some or all of the following types of PI and
                SPI:
                <br />
                <br />
                Identifiers, such as name, postal address(es), license numbers,
                passport number, state ID care numbers, and Social Security
                Numbers
                <br />
                <br />
                Commercial information, such as records of personal property or
                other products or services purchased, obtained, or considered
                <br />
                <br />
                Internet or other electronic network activity information
                <br />
                <br />
                Professional or employment-related information
                <br />
                <br />
                Educational records
                <br />
                <br />
                Financial and credit history
                <br />
                <br />
                <strong>
                  Your signature hereto will also permit EV3 to collect future
                  information from Company regarding your candidacy and/or
                  employment including dates of hire, promotion, and
                  termination, as applicable.
                </strong>{" "}
                Please note that EV3 does not solicit, share, or use qualitative
                data from employers such as performance evaluations or
                disciplinary histories.
                <br />
                <br />
                Subject to applicable law, EV3 may collect this information in a
                variety of ways, including from application forms, curricula
                vitae (or résumés), identity documents, third parties (such as
                current and former employers, recruiters, or government bodies).
                <br />
                <br />
                The Company will receive from EV3 only such information as is
                permitted under the laws applicable to the particular
                jurisdiction in which the Company operates and/or in which you
                reside unless you affirmatively consent to the collection and
                disclosure of additional information in writing, after being
                provided with notice.
                <br />
                <br />
                <strong>
                  Your consent to EV3’s collection and processing of data
                  pursuant to this Authorization is entirely optional,
                  voluntary, and not a condition of prospective or continued
                  employment.
                </strong>{" "}
                In addition, your preferences regarding your Personal
                Information and Sensitive Personal Information are important to
                EV3. EV3 employs appropriate safeguards to preserve the
                confidentiality of PI and SPI and does not sell or disclose
                identifiable information with third parties except as provided
                herein, as specifically authorized by you, or as required by
                law. Unless you specify otherwise, information may be shared
                with vendors pursuant to contract, or subsidiaries and
                affiliates of EV3 for purposes of research or service
                enhancement, and your PI and SPI may be retained for as long as
                EV3 deems reasonably necessary to fulfill these purposes. To
                withdraw your consent or specify limitations on collection and
                use of PI and SPI, please email{" "}
                <a href="mailto:customerservice@ev3global.io">
                  customerservice@ev3global.io
                </a>
                .
                <br />
                <br />
                Your signature on this Authorization signifies your consent to
                EV3’s collection and processing of your Personal Information and
                Sensitive Personal Information for all uses and/or disclosures
                not prohibited by law. Your consent remains in effect from the
                date of signature below unless and until revoked in writing.
              </Text>
            </div>
          </div>
        );
    }
  };

  const handleStep = () => {
    if (step === 1) {
      setStep((prev) => prev + 1);
    } else {
      navigate("/onboarding/basic-info");
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={cs(classes.child, { [classes.summary]: step === 2 })}>
        <Logo />
        {renderChild()}
      </div>
      <div className={classes.buttonContainer}>
        <Button onClick={handleStep}>Accept</Button>
      </div>
    </div>
  );
};

export default Intro;
