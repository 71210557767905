/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Button from "src/components/Button";
import ErrorMessage from "src/components/ErrorMessage";
import FullPageLoader from "src/components/FullPageLoader";
import Input from "src/components/Input";
import Options from "src/components/Options";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { candidateOptions, questions } from "src/utils/constants";
import useData from "src/utils/useData";
import classes from "./CandidateQuestions.module.scss";

type FormProps = {
  age_18: boolean | undefined;
  eligible_to_work: boolean | undefined;
  h1b_visa: boolean | undefined;
  dish_net: boolean | undefined;
  dish_net_relatives: boolean | undefined;
  relatives_name_pos: string | undefined;
  salary_req: number | undefined;
};

type Props = {
  handleNext: () => void;
  buttonText: string;
  step: number;
};
const CandidateQuestions: React.FC<Props> = ({
  handleNext,
  buttonText,
  step,
}) => {
  const formSchema = Yup.object().shape({
    age_18: Yup.boolean().required("Select an option"),
    eligible_to_work: Yup.boolean().required("Select an option"),
    h1b_visa: Yup.boolean().required("Select an option"),
    dish_net: Yup.boolean().required("Select an option"),
    dish_net_relatives: Yup.boolean().required("Select an option"),
    relatives_name_pos: Yup.string().when("dish_net_relatives", {
      is: true,
      then: () =>
        Yup.string()
          .required(
            "Please enter the name and relationship with your relative. ",
          )
          .trim(),
    }),
    salary_req: Yup.number()
      .required("This is required")
      .positive("Salary must be positive"),
  });
  const { postCandidateQuestionsData } = useActions();
  const {
    onboarding: {
      candidateQuestions: { error, loading, data },
    },
  } = useData();

  const {
    age_18,
    eligible_to_work,
    h1b_visa,
    dish_net,
    dish_net_relatives,
    relatives_name_pos,
    salary_req,
  } = data;

  let finishLater = false;
  const handleFinishLater = async (values: FormProps) => {
    //? NOTE: If dish_net_relatives is set to False,relatives_name_pos should be excluded
    const modifiedValues = { ...values };
    if (!values.dish_net_relatives) {
      delete modifiedValues.relatives_name_pos;
    }
    //? NOTE: for empty string of field ("") BackEnd throws error
    Object.keys(modifiedValues).forEach((key) => {
      if (
        modifiedValues[
          key as
            | "age_18"
            | "eligible_to_work"
            | "h1b_visa"
            | "dish_net"
            | "dish_net_relatives"
            | "relatives_name_pos"
            | "salary_req"
        ] === ""
      ) {
        delete modifiedValues[
          key as
            | "age_18"
            | "eligible_to_work"
            | "h1b_visa"
            | "dish_net"
            | "dish_net_relatives"
            | "relatives_name_pos"
            | "salary_req"
        ];
      }
    });
    finishLater = true;
    const resp: any = await postCandidateQuestionsData(
      {
        ...modifiedValues,
        finish_later: finishLater,
      },
      step,
    );
    if (resp?.success) handleNext();
    finishLater = false;
  };
  return (
    <div className={classes.formWrapper}>
      {loading && <FullPageLoader />}
      <div className={classes.title}>
        <Text size={24} fontWeight="bold">
          Candidate Questions:
        </Text>
        <Text>*required field.</Text>
      </div>
      <Formik
        initialValues={{
          age_18,
          eligible_to_work,
          h1b_visa,
          dish_net,
          dish_net_relatives,
          relatives_name_pos,
          salary_req,
        }}
        validateOnMount
        validationSchema={formSchema}
        onSubmit={async (values: FormProps) => {
          finishLater = false;
          //? NOTE: If dish_net_relatives is set to False,relatives_name_pos should be excluded
          const modifiedValues = { ...values };
          if (!values.dish_net_relatives) {
            delete modifiedValues.relatives_name_pos;
          }
          const resp: any = await postCandidateQuestionsData(
            {
              ...modifiedValues,
              finish_later: finishLater,
            },
            step,
          );

          if (resp?.success) handleNext();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          isValid,
        }: FormikProps<FormProps>) => {
          return (
            <>
              <Form>
                <div>
                  {questions.map(({ name, question, required }, index) => (
                    <div key={index} className={classes.form}>
                      <Text
                        id={name}
                        required={required}
                        className={classes.question}
                      >
                        {question}
                      </Text>
                      <Options
                        name={name}
                        key={index}
                        id={name}
                        wrapperClassName={classes.optionsWrapper}
                        options={candidateOptions}
                        value={values[name]}
                        onSelect={(value: boolean | undefined) => {
                          setFieldValue(name, value);
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className={classes.inputWrapper}>
                  <Input
                    placeholder="Enter Here"
                    label="If yes, please state their name(s) and relationship."
                    name="relatives_name_pos"
                    value={values.relatives_name_pos}
                    error={errors.relatives_name_pos}
                    touched={touched.relatives_name_pos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={values.dish_net_relatives}
                  />
                  <Input
                    placeholder="Enter Here"
                    label="What is your salary requirement?*"
                    name="salary_req"
                    value={values.salary_req}
                    error={errors.salary_req}
                    touched={touched.salary_req}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    leftIcon="$"
                    iconClassName={classes.dollarIcon}
                  />
                </div>
                {error && <ErrorMessage errorMessage={error} />}
                <div className={classes.buttonContainer}>
                  <Button type="submit" disabled={!isValid || loading}>
                    {buttonText}
                  </Button>
                  <Button
                    variant="text"
                    type="button"
                    disabled={
                      loading ||
                      (values.dish_net_relatives &&
                        (values.relatives_name_pos === undefined ||
                          values.relatives_name_pos.trim() === ""))
                    }
                    className={classes.finishLater}
                    onClick={() => handleFinishLater(values)}
                  >
                    Finish Later
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default CandidateQuestions;
