/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from "classnames";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { CalenderIcon, CancelIcon } from "src/assets/icons";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import FullPageLoader from "src/components/FullPageLoader";
import Input from "src/components/Input";
import RadioGroup from "src/components/RadioGroup";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { taxpayerIdFormat } from "src/utils/helper";
import useData from "src/utils/useData";
import classes from "./IRSForm.module.scss";

import { FormProps, formSchema } from "./type";
type Props = {
  handleNext: () => void;
};

const IRSForm: React.FC<Props> = ({ handleNext }) => {
  const [loading, setLoading] = useState(false);
  const { postIrsData, getIrsData } = useActions();
  const {
    onboarding: {
      irsData: { data, loading: irsDataLoading },
    },
  } = useData();

  const {
    first_name,
    middle_name,
    last_name,
    taxpayer_id,
    prev_first_name,
    prev_middle_name,
    prev_last_name,
    spouse_first_name,
    spouse_middle_name,
    spouse_last_name,
    spouse_taxpayer_id,
    prev_spouse_first_name,
    prev_spouse_middle_name,
    prev_spouse_last_name,
    address,
    city,
    state,
    zip,
    prev_address,
    prev_city,
    prev_state,
    prev_zip,
    ives_name,
    ives_id,
    sor_mbx_id,
    ives_address,
    ives_city,
    ives_state,
    ives_zip,
    customer_file_nr,
    unique_id,
    client_name,
    client_phone,
    client_address,
    client_city,
    client_state,
    client_zip,
    transcript_req,
    transcript_type,
    wage_transcript,
    wage_forms_1,
    wage_forms_2,
    wage_forms_3,
    taxpayer_line_1a,
    taxpayer_line_2a,
    tax_year_1,
    tax_year_2,
    tax_year_3,
    tax_year_4,
    signatory,
    signature_1a,
    signature_1a_date,
    signature_1a_phone,
    form_4506c,
    signatory_signed,
    print_name,
    estate_title,
    spouse_signature,
    spouse_signature_date,
    spouse_form_4506c,
    spouse_signatory_signed,
    spouse_print_name,
  } = data;
  const initialValues = {
    first_name: first_name || "",
    middle_name: middle_name || "",
    last_name: last_name || "",
    taxpayer_id: taxpayer_id || "",
    prev_first_name: prev_first_name || "",
    prev_middle_name: prev_middle_name || "",
    prev_last_name: prev_last_name || "",
    spouse_first_name: spouse_first_name || "",
    spouse_middle_name: spouse_middle_name || "",
    spouse_last_name: spouse_last_name || "",
    spouse_taxpayer_id: spouse_taxpayer_id || "",
    prev_spouse_first_name: prev_spouse_first_name || "",
    prev_spouse_middle_name: prev_spouse_middle_name || "",
    prev_spouse_last_name: prev_spouse_last_name || "",
    address: address || "",
    city: city || "",
    state: state || "",
    zip: zip || "",
    prev_address: prev_address || "",
    prev_city: prev_city || "",
    prev_state: prev_state || "",
    prev_zip: prev_zip || "",
    ives_name: ives_name || "",
    ives_id: ives_id || "",
    sor_mbx_id: sor_mbx_id || "",
    ives_address: ives_address || "",
    ives_city: ives_city || "",
    ives_state: ives_state || "",
    ives_zip: ives_zip || "",
    customer_file_nr: customer_file_nr || "",
    unique_id: unique_id || "",
    client_name: client_name || "",
    client_phone: client_phone || "",
    client_address: client_address || "",
    client_city: client_city || "",
    client_state: client_state || "",
    client_zip: client_zip || "",
    transcript_req: transcript_req || "",
    transcript_type: transcript_type || "",
    wage_transcript: wage_transcript ? true : false,
    wage_forms_1: wage_forms_1 || "",
    wage_forms_2: wage_forms_2 || "",
    wage_forms_3: wage_forms_3 || "",
    taxpayer_line_1a: taxpayer_line_1a ? true : false,
    taxpayer_line_2a: taxpayer_line_2a ? true : false,
    tax_year_1: tax_year_1 || undefined,
    tax_year_2: tax_year_2 || undefined,
    tax_year_3: tax_year_3 || undefined,
    tax_year_4: tax_year_4 || undefined,
    signatory: signatory ? true : false,
    signature_1a: signature_1a || "",
    signature_1a_date: signature_1a_date || new Date().toISOString(),
    signature_1a_phone: signature_1a_phone || "",
    form_4506c: form_4506c ? true : false,
    signatory_signed: signatory_signed ? true : false,
    print_name: print_name || "",
    estate_title: estate_title || "",
    spouse_signature: spouse_signature || "",
    spouse_signature_date: spouse_signature_date || new Date().toISOString(),
    spouse_form_4506c: spouse_form_4506c ? true : false,
    spouse_signatory_signed: spouse_signatory_signed ? true : false,
    spouse_print_name: spouse_print_name || "",
  };

  useEffect(() => {
    getIrsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {irsDataLoading ? (
        <FullPageLoader />
      ) : (
        <div className={classes.formWrapper}>
          <div className={classes.header}>
            <div className={classes.title}>Edit Form 4506-C fields</div>
            <div className={classes.cursor}>
              <CancelIcon onClick={handleNext} />
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validateOnMount
            validationSchema={formSchema}
            onSubmit={async (values: FormProps) => {
              setLoading(true);
              const resp: any = await postIrsData({
                ...values,
                wage_transcript: values.wage_transcript ? 1 : 0,
                taxpayer_line_1a: values.taxpayer_line_1a ? 1 : 0,
                taxpayer_line_2a: values.taxpayer_line_2a ? 1 : 0,
                signatory: values.signatory ? 1 : 0,
                form_4506c: values.form_4506c ? 1 : 0,
                signatory_signed: values.signatory_signed ? 1 : 0,
                spouse_form_4506c: values.spouse_form_4506c ? 1 : 0,
                spouse_signatory_signed: values.spouse_signatory_signed ? 1 : 0,
                transcript_req: values.transcript_req.toString(),
                wage_forms_1: values.wage_forms_1.toString(),
                wage_forms_2: values.wage_forms_2.toString(),
                wage_forms_3: values.wage_forms_3.toString(),
              });
              setLoading(false);
              if (resp?.success) handleNext();
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              errors,
            }: FormikProps<FormProps>) => {
              return (
                <Form>
                  {loading && <FullPageLoader />}

                  <div className={classes.note}>
                    <Text className={classes.noteText}>
                      Do not sign this form unless all applicable lines have
                      been completed.
                    </Text>
                    <Text className={classes.noteText}>
                      Request may be rejected if the form is incomplete or
                      illegible.
                    </Text>
                    <Text fontWeight="bold" className={classes.noteText}>
                      For more information about Form 4506-C, visit www.irs.gov
                      and search IVES
                    </Text>
                  </div>
                  <div className={classes.multiInputWrapper}>
                    <label className={classes.multiInputLabel}>
                      <span>1a.</span> Current Name
                    </label>
                    <div className={classes.multiInput}>
                      <Input
                        placeholder="Enter Here"
                        label="i. First Name"
                        id="first_name"
                        name="first_name"
                        value={values.first_name}
                        error={errors.first_name}
                        touched={touched.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="ii. Middle initial"
                        id="middle_name"
                        name="middle_name"
                        value={values.middle_name}
                        error={errors.middle_name}
                        touched={touched.middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        inputWrapperClassName={classes.noMargin}
                        placeholder="Enter Here"
                        label="iii. Last name/BMF Company Name"
                        id="last_name"
                        name="last_name"
                        value={values.last_name}
                        error={errors.last_name}
                        touched={touched.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      htmlFor="taxpayer_id"
                      className={cn(classes.inputLabel, classes.marginBottom)}
                    >
                      <span>1b.</span> First taxpayer identification number (see
                      instructions)
                    </label>
                    <Field name="taxpayer_id">
                      {({ form }: FieldProps) => {
                        return (
                          <Input
                            id="taxpayer_id"
                            name="taxpayer_id"
                            placeholder="Enter Here"
                            value={values.taxpayer_id}
                            error={errors.taxpayer_id}
                            touched={touched.taxpayer_id}
                            onChange={(e) => {
                              const id = taxpayerIdFormat(e.target.value);
                              form.setFieldValue("taxpayer_id", id);
                            }}
                            onBlur={handleBlur}
                            maxLength={11}
                          />
                        );
                      }}
                    </Field>
                  </div>

                  <div className={classes.multiInputWrapper}>
                    <label className={classes.multiInputLabel}>
                      <span>1c.</span> Previous name shown on the last return
                      filed if different form line 1a
                    </label>
                    <div className={classes.multiInput}>
                      <Input
                        placeholder="Enter Here"
                        label="i. First Name"
                        id="prev_first_name"
                        name="prev_first_name"
                        value={values.prev_first_name}
                        error={errors.prev_first_name}
                        touched={touched.prev_first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="ii. Middle initial"
                        id="prev_middle_name"
                        name="prev_middle_name"
                        value={values.prev_middle_name}
                        error={errors.prev_middle_name}
                        touched={touched.prev_middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        inputWrapperClassName={classes.noMargin}
                        placeholder="Enter Here"
                        label="iii. Last name"
                        id="prev_last_name"
                        name="prev_last_name"
                        value={values.prev_last_name}
                        error={errors.prev_last_name}
                        touched={touched.prev_last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className={classes.multiInputWrapper}>
                    <label className={classes.multiInputLabel}>
                      <span>2a. Spouse’s current name</span>
                      <br /> (if joint return and transcripts are requested for
                      both taxpayers)
                    </label>
                    <div className={classes.multiInput}>
                      <Input
                        placeholder="Enter Here"
                        label="i. Spouse’s First Name"
                        id="spouse_first_name"
                        name="spouse_first_name"
                        value={values.spouse_first_name}
                        error={errors.spouse_first_name}
                        touched={touched.spouse_first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="ii. Spouse’s Middle initial"
                        id="spouse_middle_name"
                        name="spouse_middle_name"
                        value={values.spouse_middle_name}
                        error={errors.spouse_middle_name}
                        touched={touched.spouse_middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        inputWrapperClassName={classes.noMargin}
                        placeholder="Enter Here"
                        label="iii. Spouse’s Last name/BMF Company Name"
                        id="spouse_last_name"
                        name="spouse_last_name"
                        value={values.spouse_last_name}
                        error={errors.spouse_last_name}
                        touched={touched.spouse_last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className={classes.inputWrapper}>
                    <label
                      htmlFor="spouseTaxPayerNumber"
                      className={cn(classes.inputLabel, classes.marginBottom)}
                    >
                      <span>2b.</span> Spouse’s taxpayer identification number
                      (if joint return and transcripts are requested for both
                      taxpayers)
                    </label>
                    <Field name="spouse_taxpayer_id">
                      {({ form }: FieldProps) => {
                        return (
                          <Input
                            id="spouse_taxpayer_id"
                            name="spouse_taxpayer_id"
                            placeholder="Enter Here"
                            value={values.spouse_taxpayer_id}
                            error={errors.spouse_taxpayer_id}
                            touched={touched.spouse_taxpayer_id}
                            onChange={(e) => {
                              const id = taxpayerIdFormat(e.target.value);
                              form.setFieldValue("spouse_taxpayer_id", id);
                            }}
                            onBlur={handleBlur}
                            maxLength={11}
                          />
                        );
                      }}
                    </Field>
                  </div>

                  <div className={classes.multiInputWrapper}>
                    <label className={classes.multiInputLabel}>
                      <span>2c.</span>
                      Spouse’s previous name shown on the last return filed if
                      different form line 2a
                    </label>
                    <div className={classes.multiInput}>
                      <Input
                        placeholder="Enter Here"
                        label="i. First Name"
                        id="prev_spouse_first_name"
                        name="prev_spouse_first_name"
                        value={values.prev_spouse_first_name}
                        error={errors.prev_spouse_first_name}
                        touched={touched.prev_spouse_first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="ii. Middle initial"
                        id="prev_spouse_middle_name"
                        name="prev_spouse_middle_name"
                        value={values.prev_spouse_middle_name}
                        error={errors.prev_spouse_middle_name}
                        touched={touched.prev_spouse_middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        inputWrapperClassName={classes.noMargin}
                        placeholder="Enter Here"
                        label="iii. Last name"
                        id="prev_spouse_last_name"
                        name="prev_spouse_last_name"
                        value={values.prev_spouse_last_name}
                        error={errors.prev_spouse_last_name}
                        touched={touched.prev_spouse_last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className={classes.inputWrapper}>
                    <label
                      className={cn(classes.inputLabel, classes.marginBottom)}
                      htmlFor="currentAddress"
                    >
                      <span>3. Current Address</span>
                    </label>
                    <div id="currentAddress">
                      <Input
                        label="a. Street address (including apt, room or suite no.)"
                        id="address"
                        name="address"
                        placeholder="Enter Here"
                        value={values.address}
                        error={errors.address}
                        touched={touched.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        label="b. City"
                        id="city"
                        name="city"
                        placeholder="Enter Here"
                        value={values.city}
                        error={errors.city}
                        touched={touched.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={classes.inputContainer}>
                        <Input
                          label="c. State"
                          id="state"
                          name="state"
                          placeholder="Enter Here"
                          value={values.state}
                          error={errors.state}
                          touched={touched.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={2}
                        />
                        <Input
                          label="d. ZIP code"
                          id="zip"
                          name="zip"
                          placeholder="Enter Here"
                          value={values.zip}
                          error={errors.zip}
                          touched={touched.zip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      className={cn(classes.inputLabel, classes.marginBottom)}
                      htmlFor="previousAddress"
                    >
                      <span>4.</span> Previous address shown on the last return
                      filed if different from line 3
                    </label>

                    <div id="previousAddress">
                      <Input
                        label="a. Street address (including apt, room or suite no.)"
                        id="prev_address"
                        name="prev_address"
                        placeholder="Enter Here"
                        value={values.prev_address}
                        error={errors.prev_address}
                        touched={touched.prev_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        label="b. City"
                        id="prev_city"
                        name="prev_city"
                        placeholder="Enter Here"
                        value={values.prev_city}
                        error={errors.prev_city}
                        touched={touched.prev_city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={classes.inputContainer}>
                        <Input
                          label="c. State"
                          id="prev_state"
                          name="prev_state"
                          placeholder="Enter Here"
                          value={values.prev_state}
                          error={errors.prev_state}
                          touched={touched.prev_state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={2}
                        />
                        <Input
                          label="d. ZIP code"
                          id="prev_zip"
                          name="prev_zip"
                          placeholder="Enter Here"
                          value={values.prev_zip}
                          error={errors.prev_zip}
                          touched={touched.prev_zip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={classes.multiInputWrapper}>
                    <label className={classes.multiInputLabel}>
                      <span>5.</span> IVES participant name, ID number, SOR
                      mailbox ID and address
                    </label>
                    <div className={classes.multiInput}>
                      <Input
                        placeholder="Enter Here"
                        label="i. IVES participant name"
                        id="ives_name"
                        name="ives_name"
                        value={values.ives_name}
                        error={errors.ives_name}
                        touched={touched.ives_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="ii. IVES participant ID number"
                        id="ives_id"
                        name="ives_id"
                        value={values.ives_id}
                        error={errors.ives_id}
                        touched={touched.ives_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="iii. SOR mailbox ID"
                        id="sor_mbx_id"
                        name="sor_mbx_id"
                        value={values.sor_mbx_id}
                        error={errors.sor_mbx_id}
                        touched={touched.sor_mbx_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        label="iv. Street address (including apt., room, or suite no.)"
                        id="ives_address"
                        name="ives_address"
                        placeholder="Enter Here"
                        value={values.ives_address}
                        error={errors.ives_address}
                        touched={touched.ives_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        label="v. City"
                        id="ives_city"
                        name="ives_city"
                        placeholder="Enter Here"
                        value={values.ives_city}
                        error={errors.ives_city}
                        touched={touched.ives_city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={classes.inputContainer}>
                        <Input
                          label="vi. State"
                          id="ives_state"
                          name="ives_state"
                          placeholder="Enter Here"
                          value={values.ives_state}
                          error={errors.ives_state}
                          touched={touched.ives_state}
                          maxLength={2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputWrapperClassName={classes.noMargin}
                        />
                        <Input
                          label="vii. ZIP code"
                          id="ives_zip"
                          name="ives_zip"
                          placeholder="Enter Here"
                          value={values.ives_zip}
                          error={errors.ives_zip}
                          touched={touched.ives_zip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputWrapperClassName={classes.noMargin}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      htmlFor="customer_file_nr"
                      className={cn(classes.inputLabel, classes.marginBottom)}
                    >
                      <span>5b.</span> Customer file number (if applicable)
                    </label>
                    <Input
                      id="customer_file_nr"
                      name="customer_file_nr"
                      placeholder="Enter Here"
                      value={values.customer_file_nr}
                      error={errors.customer_file_nr}
                      touched={touched.customer_file_nr}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      htmlFor="unique_id"
                      className={cn(classes.inputLabel, classes.marginBottom)}
                    >
                      <span>5c.</span> Unique Identifier (if applicable)
                    </label>
                    <Input
                      id="unique_id"
                      name="unique_id"
                      placeholder="Enter Here"
                      value={values.unique_id}
                      error={errors.unique_id}
                      touched={touched.unique_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className={classes.multiInputWrapper}>
                    <label className={classes.multiInputLabel}>
                      <span>5d.</span> Client name, telephone number, and
                      address (this field cannot be blank or not applicable (NA)
                    </label>
                    <div className={classes.multiInput}>
                      <Input
                        placeholder="Enter Here"
                        label="i. Client name"
                        id="client_name"
                        name="client_name"
                        value={values.client_name}
                        error={errors.client_name}
                        touched={touched.client_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="ii. Telephone number"
                        id="client_phone"
                        name="client_phone"
                        value={values.client_phone}
                        error={errors.client_phone}
                        touched={touched.client_phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={10}
                      />
                      <Input
                        placeholder="Enter Here"
                        label="iii. Street address"
                        id="client_address"
                        name="client_address"
                        value={values.client_address}
                        error={errors.client_address}
                        touched={touched.client_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        label="iv. City"
                        id="client_city"
                        name="client_city"
                        placeholder="Enter Here"
                        value={values.client_city}
                        error={errors.client_city}
                        touched={touched.client_city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={classes.inputContainer}>
                        <Input
                          label="v. State"
                          id="client_state"
                          name="client_state"
                          placeholder="Enter Here"
                          value={values.client_state}
                          error={errors.client_state}
                          touched={touched.client_state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={2}
                          inputWrapperClassName={classes.noMargin}
                        />
                        <Input
                          label="vi. ZIP code"
                          id="client_zip"
                          name="client_zip"
                          placeholder="Enter Here"
                          value={values.client_zip}
                          error={errors.client_zip}
                          touched={touched.client_zip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputWrapperClassName={classes.noMargin}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      htmlFor="taxFormNumber"
                      className={cn(classes.inputLabel, classes.marginBottom)}
                    >
                      <span>6. Transcript requested.</span>
                      <br /> Enter the tax form number here (1040, 1065, 1120,
                      etc.) and check the appropriate box below. Enter only one
                      tax form number per request for line 6 transcripts.
                    </label>
                    <Input
                      id="transcript_req"
                      name="transcript_req"
                      placeholder="Enter Here"
                      value={values.transcript_req}
                      error={errors.transcript_req}
                      touched={touched.transcript_req}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                    />
                  </div>
                  <div className={classes.radioGroup}>
                    <RadioGroup
                      options={[
                        {
                          label: "a. Return Transcript, ",
                          value: "return",
                        },
                        {
                          label: "b. Account Transcript, ",
                          value: "account",
                        },
                        {
                          label: "c. Record of Account, ",
                          value: "record",
                        },
                      ]}
                      selectedValue={values.transcript_type}
                      onChange={(value) => {
                        setFieldValue("transcript_type", value);
                      }}
                      error={errors.transcript_type}
                      touched={touched.transcript_type}
                      name="identification"
                      onBlur={handleBlur}
                      labelClassName={classes.radioLabel}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      htmlFor="taxFormNumber"
                      className={classes.inputLabel}
                    >
                      <span>7. Wage and Income transcript.</span>
                      <br /> (W-2, 1098, 1099-G, etc.)
                    </label>
                  </div>
                  <div className={classes.multiInputWrapper}>
                    <CheckBox
                      labelClassName={classes.checkBoxBoldLabel}
                      checked={values.wage_transcript}
                      id="wage_transcript"
                      label="Select"
                      touched={touched.wage_transcript}
                      error={errors.wage_transcript}
                      name="wage_transcript"
                      wrapperClassName={classes.inputCheckBox}
                      onChange={handleChange}
                    />
                    <label className={classes.multiInputLabel}>
                      <span>a.</span> Enter a max of three form numbers here; if
                      no entry is made, all forms will be sent.
                    </label>
                    <div className={classes.multiInput}>
                      <Input
                        placeholder="Form 1"
                        id="wage_forms_1"
                        name="wage_forms_1"
                        value={values.wage_forms_1}
                        error={errors.wage_forms_1}
                        touched={touched.wage_forms_1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                      />
                      <Input
                        placeholder="Form 2"
                        id="wage_forms_2"
                        name="wage_forms_2"
                        value={values.wage_forms_2}
                        error={errors.wage_forms_2}
                        touched={touched.wage_forms_2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                      />
                      <Input
                        placeholder="Form 3"
                        id="wage_forms_3"
                        name="wage_forms_3"
                        value={values.wage_forms_3}
                        error={errors.wage_forms_3}
                        touched={touched.wage_forms_3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        inputWrapperClassName={classes.noMargin}
                      />
                    </div>
                    <label className={classes.multiInputLabel}>
                      <span>b. </span>
                      Mark the checkbox for taxpayer(s) requesting the wage and
                      income transcripts. If no box is checked, transcripts will
                      be provided for all listed taxpayers.
                    </label>
                    <CheckBox
                      labelClassName={classes.checkBoxBoldLabel}
                      checked={values.taxpayer_line_1a}
                      id="taxpayer_line_1a"
                      label="Line 1a"
                      touched={touched.taxpayer_line_1a}
                      error={errors.taxpayer_line_1a}
                      name="taxpayer_line_1a"
                      wrapperClassName={classes.inputCheckBox}
                      onChange={handleChange}
                    />
                    <CheckBox
                      labelClassName={classes.checkBoxBoldLabel}
                      checked={values.taxpayer_line_2a}
                      id="taxpayer_line_2a"
                      label="Line 2a"
                      touched={touched.taxpayer_line_2a}
                      error={errors.taxpayer_line_2a}
                      name="taxpayer_line_2a"
                      wrapperClassName={classes.inputCheckBox}
                      onChange={handleChange}
                    />
                  </div>

                  <div className={classes.inputWrapper}>
                    <label className={classes.inputLabel}>
                      <span>8. Year or period requested.</span>
                      <br /> Enter the ending date of the tax year or period
                      using the mm dd yyyy format.
                    </label>
                  </div>
                  <div className={classes.multiInputWrapper}>
                    <div className={classes.multiInput}>
                      <Field name="tax_year_1">
                        {({ field, form }: FieldProps) => {
                          return (
                            <ReactDatePicker
                              icon={<CalenderIcon />}
                              showIcon={true}
                              autoComplete="off"
                              calendarIconClassname={classes.calenderIcon}
                              wrapperClassName={classes.datePickerWrapper}
                              placeholderText="Select Date"
                              className={cn(
                                classes.datePicker,

                                {
                                  [classes.isInvalid]:
                                    touched.tax_year_1 && errors.tax_year_1,
                                },
                              )}
                              dateFormat="MM/dd/yyyy"
                              selected={
                                field.value ? new Date(field.value) : undefined
                              }
                              onBlur={handleBlur}
                              name="tax_year_1"
                              onChange={(date) => {
                                form.setFieldValue(
                                  "tax_year_1",
                                  date?.toISOString(),
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                      <Field name="tax_year_2">
                        {({ field, form }: FieldProps) => {
                          return (
                            <ReactDatePicker
                              showIcon={true}
                              icon={<CalenderIcon />}
                              calendarIconClassname={classes.calenderIcon}
                              wrapperClassName={classes.datePickerWrapper}
                              autoComplete="off"
                              placeholderText="Select Date"
                              className={cn(
                                classes.datePicker,

                                {
                                  [classes.isInvalid]:
                                    touched.tax_year_2 && errors.tax_year_2,
                                },
                              )}
                              dateFormat="MM/dd/yyyy"
                              selected={
                                field.value ? new Date(field.value) : undefined
                              }
                              onBlur={handleBlur}
                              name="tax_year_2"
                              onChange={(date) => {
                                form.setFieldValue(
                                  "tax_year_2",
                                  date?.toISOString(),
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                      <Field name="tax_year_3">
                        {({ field, form }: FieldProps) => {
                          return (
                            <ReactDatePicker
                              showIcon={true}
                              icon={<CalenderIcon />}
                              calendarIconClassname={classes.calenderIcon}
                              wrapperClassName={classes.datePickerWrapper}
                              autoComplete="off"
                              placeholderText="Select Date"
                              className={cn(
                                classes.datePicker,

                                {
                                  [classes.isInvalid]:
                                    touched.tax_year_3 && errors.tax_year_3,
                                },
                              )}
                              dateFormat="MM/dd/yyyy"
                              selected={
                                field.value ? new Date(field.value) : undefined
                              }
                              onBlur={handleBlur}
                              name="tax_year_3"
                              onChange={(date) => {
                                form.setFieldValue(
                                  "tax_year_3",
                                  date?.toISOString(),
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                      <Field name="tax_year_4">
                        {({ field, form }: FieldProps) => {
                          return (
                            <ReactDatePicker
                              icon={<CalenderIcon />}
                              showIcon={true}
                              calendarIconClassname={classes.calenderIcon}
                              wrapperClassName={cn(
                                classes.datePickerWrapper,
                                classes.noMargin,
                              )}
                              autoComplete="off"
                              placeholderText="Select Date"
                              className={cn(classes.datePicker, {
                                [classes.isInvalid]:
                                  touched.tax_year_4 && errors.tax_year_4,
                              })}
                              dateFormat="MM/dd/yyyy"
                              selected={
                                field.value ? new Date(field.value) : undefined
                              }
                              onBlur={handleBlur}
                              name="tax_year_4"
                              onChange={(date) => {
                                form.setFieldValue(
                                  "tax_year_4",
                                  date?.toISOString(),
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                  <div className={classes.multiInputWrapper}>
                    <div className={classes.multiInput}>
                      <CheckBox
                        checked={values.signatory}
                        boldLabel="Signatory"
                        label="attests that he/she has read the attestation clause and upon so reading declares that he/she has the authority to sign the Form 4506-T. (See instructions.)"
                        touched={touched.signatory}
                        error={errors.signatory}
                        id="signatory"
                        name="signatory"
                        onChange={handleChange}
                        wrapperClassName={cn(
                          classes.signCheckBox,
                          classes.noMargin,
                        )}
                      />
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.inputLabel}>
                      <span>Caution:</span>
                      <br /> Do not sign this form unless all applicable lines
                      have been completed.
                    </label>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label className={classes.inputLabel}>
                      <span>Signature of taxpayer(s).</span>
                      <br /> I declare that I am either the taxpayer whose name
                      is shown on line 1a or, if applicable, line 2a, or a
                      person authorized to obtain the tax information requested.
                      If the request applies to a joint return, at least one
                      spouse must sign; however, if both spouses' names and TINs
                      are listed in lines 1a-1b and 2a-2b, both spouses must
                      sign the request. If signed by a corporate officer, 1
                      percent or more shareholder, partner, managing member,
                      guardian, tax matters partner, executor, receiver,
                      administrator, trustee, or party other than the taxpayer,
                      I certify that I have the authority to execute Form 4506-C
                      on behalf of the taxpayer. Note: This form must be
                      received by IRS within 120 days of the signature date.
                    </label>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      className={cn(classes.inputLabel, classes.marginBottom)}
                    >
                      <span style={{ fontSize: "18px" }}>Sign Here</span>
                    </label>
                    <Input
                      label="Signature for Line 1a"
                      placeholder="Enter Here"
                      name="signature_1a"
                      id="signature_1a"
                      value={values.signature_1a}
                      touched={touched.signature_1a}
                      error={errors.signature_1a}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label className={classes.datePickerLabel}>Date</label>
                    <Field name="signature_1a_date">
                      {({ field, form }: FieldProps) => {
                        return (
                          <ReactDatePicker
                            icon={<CalenderIcon />}
                            showIcon={true}
                            autoComplete="off"
                            placeholderText="Select"
                            calendarIconClassname={classes.calenderIcon}
                            wrapperClassName={classes.datePickerWrapper}
                            className={cn(classes.datePicker, {
                              [classes.isInvalid]:
                                touched.signature_1a_date &&
                                errors.signature_1a_date,
                            })}
                            dateFormat="MM/dd/yyyy"
                            selected={
                              field.value ? new Date(field.value) : new Date()
                            }
                            onBlur={handleBlur}
                            name="signature_1a_date"
                            onChange={(date) => {
                              form.setFieldValue(
                                "signature_1a_date",
                                date?.toISOString(),
                              );
                            }}
                          />
                        );
                      }}
                    </Field>
                    <Input
                      label="Phone number of taxpayer on line 1a or 2a"
                      placeholder="Enter phone number"
                      name="signature_1a_phone"
                      id="signature_1a_phone"
                      value={values.signature_1a_phone}
                      touched={touched.signature_1a_phone}
                      error={errors.signature_1a_phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={10}
                    />
                  </div>
                  <div className={classes.marginBottom}>
                    <div
                      className={cn(
                        classes.multiInputWrapper,
                        classes.noMargin,
                      )}
                    >
                      <div className={classes.multiInput}>
                        <CheckBox
                          labelClassName={classes.checkBoxLabel}
                          checked={values.form_4506c}
                          id="form_4506c"
                          label="Form 4506-C was signed by an Authorized Representative"
                          name="form_4506c"
                          wrapperClassName={classes.inputCheckBox}
                          onChange={handleChange}
                        />
                        <CheckBox
                          labelClassName={classes.checkBoxLabel}
                          checked={values.signatory_signed}
                          id="signatory_signed"
                          label="Signatory confirms document was electronically signed"
                          name="signatory_signed"
                          wrapperClassName={classes.inputCheckBox}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {((touched.form_4506c && errors.form_4506c) ||
                      (touched.signatory_signed &&
                        errors.signatory_signed)) && (
                      <div className={classes.invalidFeedback}>
                        {errors.form_4506c || errors.signatory_signed}
                      </div>
                    )}
                  </div>
                  <Input
                    label="Print/Type Name"
                    placeholder="Enter Here"
                    name="print_name"
                    id="print_name"
                    value={values.print_name}
                    touched={touched.print_name}
                    error={errors.print_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelClassName={classes.boldLabel}
                  />

                  <div className={classes.inputWrapper}>
                    <label
                      className={cn(classes.inputLabel, classes.marginBottom)}
                      htmlFor="estate_title"
                    >
                      <span>Title</span>
                      <br />
                      (if line 1a above is a corporation, partnership, estate or
                      trust.
                    </label>
                    <Input
                      placeholder="Enter Here"
                      name="estate_title"
                      id="estate_title"
                      value={values.estate_title}
                      touched={touched.estate_title}
                      error={errors.estate_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label
                      className={cn(classes.inputLabel, classes.marginBottom)}
                      htmlFor="spouse_signature"
                    >
                      <span>Spouse’s Signature </span>
                      <br />
                      (required if listed on Line 2a
                    </label>
                    <Input
                      placeholder="Enter Here"
                      name="spouse_signature"
                      id="spouse_signature"
                      value={values.spouse_signature}
                      touched={touched.spouse_signature}
                      error={errors.spouse_signature}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={10}
                    />
                    <label className={classes.datePickerLabel}>Date</label>
                    <Field name="spouse_signature_date">
                      {({ field, form }: FieldProps) => {
                        return (
                          <ReactDatePicker
                            icon={<CalenderIcon />}
                            showIcon={true}
                            autoComplete="off"
                            placeholderText="Select"
                            calendarIconClassname={classes.calenderIcon}
                            wrapperClassName={classes.datePickerWrapper}
                            className={cn(classes.datePicker, {
                              [classes.isInvalid]:
                                touched.spouse_signature_date &&
                                errors.spouse_signature_date,
                            })}
                            dateFormat="MM/dd/yyyy"
                            selected={
                              field.value ? new Date(field.value) : new Date()
                            }
                            onBlur={handleBlur}
                            name="spouse_signature_date"
                            onChange={(date) => {
                              form.setFieldValue(
                                "spouse_signature_date",
                                date?.toISOString(),
                              );
                            }}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div className={classes.marginBottom}>
                    <div
                      className={cn(
                        classes.multiInputWrapper,
                        classes.noMargin,
                      )}
                    >
                      <div className={classes.multiInput}>
                        <CheckBox
                          labelClassName={classes.checkBoxLabel}
                          checked={values.spouse_form_4506c}
                          id="spouse_form_4506c"
                          label="Form 4506-C was signed by an Authorized Representative"
                          name="spouse_form_4506c"
                          wrapperClassName={classes.inputCheckBox}
                          onChange={handleChange}
                        />
                        <CheckBox
                          labelClassName={classes.checkBoxLabel}
                          checked={values.spouse_signatory_signed}
                          id="spouse_signatory_signed"
                          label="Signatory confirms document was electronically signed"
                          name="spouse_signatory_signed"
                          wrapperClassName={classes.inputCheckBox}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {((touched.spouse_form_4506c && errors.spouse_form_4506c) ||
                      (touched.spouse_signatory_signed &&
                        errors.spouse_signatory_signed)) && (
                      <div className={classes.invalidFeedback}>
                        {errors.spouse_form_4506c ||
                          errors.spouse_signatory_signed}
                      </div>
                    )}
                  </div>
                  <Input
                    label="Print/Type Name"
                    placeholder="Enter Here"
                    name="spouse_print_name"
                    id="spouse_print_name"
                    value={values.spouse_print_name}
                    touched={touched.spouse_print_name}
                    error={errors.spouse_print_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelClassName={classes.boldLabel}
                  />
                  <div className={classes.buttonContainer}>
                    <Button type="submit" disabled={loading} loading={loading}>
                      Done
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default IRSForm;
