import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Logo from "src/components/Logo";
import ProgressBar from "src/components/ProgressBar";
import Text from "src/components/Text/Text";
import classes from "./OnboardingHeader.module.scss";
type Props = {
  title: string;
  step: number;
  skip?: boolean;
};

const OnboardingHeader: React.FC<Props> = ({ title, step, skip }) => {
  const navigate = useNavigate();

  const handleSkip = () => {
    switch (step) {
      case 2:
        navigate("/onboarding/forms/1");
        break;
      default:
        navigate("/onboarding/basic-info");
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.child}>
        <div className={classes.logoWrapper}>
          <Logo />
          {skip && (
            <Button
              variant="text"
              onClick={handleSkip}
              className={classes.skip}
            >
              Skip
            </Button>
          )}
        </div>
        <div className={classes.progressWrapper}>
          <div className={classes.stepInfoWrapper}>
            <Text size={20} color="#111" fontWeight="bold">
              {title}
            </Text>
            <Text size={20} fontFamily="montserrat">
              <strong>Step {step}</strong>
              <span style={{ fontSize: 18 }}>/</span>
              <span style={{ fontSize: 16 }}>4</span>
            </Text>
          </div>
          <ProgressBar completed={`${25 * step}%`} />
        </div>
      </div>
    </div>
  );
};

export default OnboardingHeader;
