import React from "react";
import Text from "../Text";

type Props = {
  errorMessage: string;
};
const ErrorMessage: React.FC<Props> = (Props) => {
  const { errorMessage } = Props;
  return (
    <Text
      style={{
        textAlign: "left",
        color: "#fb6340",
        paddingBottom: "8px",
        fontSize: "14px",
      }}
    >
      {errorMessage}
    </Text>
  );
};

export default ErrorMessage;
