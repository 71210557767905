/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from "redux";
import { handleData } from "../middlewares/handleData";
import Types from "../types/auth";

const initialState = {
  user: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  uploadResume: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
};

const AuthReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.LOG_IN_USER:
      return handleData(state, action, {
        request: (prevState: any) => {
          return {
            ...prevState,
            user: {
              data: { ...(payload || {}) },
              loading: true,
            },
          };
        },
        success: (prevState) => ({
          ...prevState,
          user: {
            loading: false,
            data: { ...(state.user.data || {}), ...(payload.data || {}) },
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          user: {
            loading: false,
            error: payload,
          },
        }),
      });
    case Types.UPLOAD_RESUME:
      return handleData(state, action, {
        request: (prevState: any) => {
          return {
            ...prevState,
            uploadResume: {
              data: {
                ...(payload || {}),
              },
              loading: true,
            },
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            uploadResume: {
              loading: false,
              data: {
                ...(state?.uploadResume.data || {}),
                ...(payload?.data || {}),
              },
              error: "",
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          uploadResume: {
            loading: false,
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default AuthReducer;
