import cx from "classnames";
import React from "react";
import Button from "../Button";
import classes from "./Options.module.scss";

type Option = { label: string; value: boolean };

type Props = {
  value: boolean | undefined;
  onSelect: (option: boolean) => void;
  options: Option[];
  wrapperClassName?: string;
  name: string;
  id?: string;
};

const Options: React.FC<Props> = ({
  value: selectedValue,
  options,
  onSelect,
  wrapperClassName = "",
  id,
}) => {
  return (
    <div className={cx(classes.wrapper, wrapperClassName)}>
      {options.map(({ value, label }, index) => {
        return (
          <Button
            type="button"
            className={cx(classes.option, {
              [classes.active]: value === selectedValue,
            })}
            id={id}
            onClick={() => onSelect(value)}
            key={index}
            variant={value === selectedValue ? "fill" : "outline"}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
};

export default Options;
