import React, { useState } from "react";
import { SearchIcon } from "src/assets/icons/index";
import { Company, Provider } from "src/utils/useData";
import { CardDetails, CardType } from "../Card/types";
import Input from "../Input";
import Text from "../Text/Text";
import classes from "./Search.module.scss";

type Props = {
  searchList: Company[] | Provider[];
  handleClick: (value: CardDetails) => void;
  type: CardType;
  handleType: (type: CardType) => void;
  handleCancel: () => void;
  handleChange: (value: string) => void;
  companyLoading: boolean;
  providerLoading: boolean;
  selectedCards: CardDetails[];
};
const Search: React.FC<Props> = ({
  searchList,
  handleClick,
  type,
  handleType,
  handleCancel,
  handleChange,
  companyLoading,
  providerLoading,
  selectedCards,
}) => {
  const [search, setSearch] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const handleSearch = (searchText: string) => {
    setSearch(searchText);
    handleChange(searchText);
  };

  const selectedCardNames = selectedCards?.map(
    (card: CardDetails) => card?.tv_name.toLowerCase(),
  );

  const filteredSearchList = searchList?.filter(
    (val) =>
      val?.name.toLowerCase()?.includes(search.toLowerCase()) &&
      !selectedCardNames?.includes(val?.name.toLowerCase()),
  );

  return (
    <div className={classes.searchContainer}>
      <div className={classes.searchTitle}>
        <Text size={14} color="#111">
          {type === "company"
            ? "Search for Past Work Experience"
            : `${searchTitle} Provider`}
        </Text>
        <Text
          size={14}
          fontWeight="medium"
          color="#111"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Text>
      </div>
      <Input
        inputGroupClassName={classes.searchInputGroup}
        inputClassName={classes.searchInput}
        leftIcon={<SearchIcon />}
        placeholder="Search"
        onChange={(e) => handleSearch(e.target.value)}
      />
      {companyLoading || providerLoading ? (
        <div className={classes.loaderWrapper}>
          <span className={classes.spinner}></span>
        </div>
      ) : (
        <div className={classes.searchList}>
          {filteredSearchList.map((item: Company | Provider, index) => (
            <Text
              key={index}
              className={classes.listItem}
              onClick={() => {
                if ("id" in item) {
                  handleClick({
                    tv_name: item.name,
                    tv_type: type,
                    tv_id: item.id,
                    tv_source: "",
                  });
                } else {
                  handleClick({
                    tv_name: item.name,
                    tv_type: type,
                    tv_id: item.company_mapping_id,
                    tv_source: "",
                  });
                }
              }}
            >
              {item.name}
            </Text>
          ))}
          {filteredSearchList.length === 0 && (
            <Text className={classes.listItem}>
              Not seeing your employer?{" "}
              {type === "company" ? (
                <span
                  onClick={() => {
                    setSearchTitle(search);
                    handleType("provider");
                  }}
                >
                  Search by Provider
                </span>
              ) : (
                <span
                  onClick={() =>
                    handleClick({
                      tv_name: search,
                      tv_type: "company",
                      tv_source: "manual",
                      tv_id: `${Math.random() * 10}`,
                    })
                  }
                >
                  Manually Enter Work Details
                </span>
              )}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
