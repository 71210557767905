import { createTypes } from "./createTypes";

export default createTypes(
  "POST_CANDIDATE_QUESTIONS_DATA",
  "GET_COMPANY_LIST",
  "GET_BRIDGE_TOKEN",
  "VERIFY_EMPLOYMENT",
  "POST_VOLUNTARY_INFORMATION_DATA",
  "POST_DISABILITY_FORM_DATA",
  "POST_VETERAN_STATUS_DATA",
  "POST_PRE_EMPLOYMENT_DATA",
  "GET_EMAIL_LIST",
  "UPDATE_CANDIDATE_EMAIL",
  "SUBMIT_CANDIDATE_APPLICATION",
  "ADD_COMPANY_DATA",
  "GET_PROVIDER_LIST",
  "UPDATE_CARDS",
  "ADD_CARD",
  "REMOVE_CARD",
  "VERIFY_CARD",
  "POST_IRS_DATA",
  "GET_IRS_DATA",
  "GET_PDF",
  "POST_IRS_SIGN",
  "POST_IRS_SIGN_DATA",
);
