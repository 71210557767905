/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from "redux";
import {
  ApplicationData,
  CandidateData,
  CompanyData,
  DisabilityInformation,
  IrsForm,
  PreEmploymentQuestionsData,
  VerifyEmploymentData,
  VeteranStatus,
  VoluntaryInformation,
  getBridgeToken as getBridgeTokenApi,
  getCards as getCardsApi,
  getCompanyList as getCompanyListApi,
  getEmailList as getEmailListApi,
  getIrsData as getIrsDataApi,
  getPdf as getPdfApi,
  getProviderList as getProviderListApi,
  postApplicantData as postApplicantDataApi,
  postCompanyManually as postCompanyManuallyApi,
  postIrsData as postIrsDataApi,
  postIrsSign as postIrsSignApi,
  postIrsSignData as postIrsSignDataApi,
  submitApplication as submitApplicationApi,
  updateEmail as updateEmailApi,
  verifyEmployment as verifyEmploymentApi,
} from "src/api/onboarding";
import { CardDetails } from "src/components/Card/types";
import Types from "../types/onboarding";

export const postCandidateQuestionsData =
  (data: CandidateData, step: number, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.POST_CANDIDATE_QUESTIONS_DATA,
      asyncCall: () => {
        return postApplicantDataApi(data, step);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getCompanyList =
  (search: string = "", cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_COMPANY_LIST,
      asyncCall: async () => {
        return getCompanyListApi(search);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getBridgeToken =
  (companyMappingId: string, type: string, cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_BRIDGE_TOKEN,
      asyncCall: async () => {
        return getBridgeTokenApi(companyMappingId, type);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const verifyEmployment =
  (data: VerifyEmploymentData, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_CARDS,
      asyncCall: async () => {
        return verifyEmploymentApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const postVoluntaryInformationData =
  (data: VoluntaryInformation, step: number, cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.POST_VOLUNTARY_INFORMATION_DATA,
      asyncCall: () => {
        return postApplicantDataApi(data, step);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const postDisabilityInformationData =
  (data: DisabilityInformation, step: number, cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.POST_DISABILITY_FORM_DATA,
      asyncCall: () => {
        return postApplicantDataApi(data, step);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const postVeteranStatusData =
  (data: VeteranStatus, step: number, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.POST_VETERAN_STATUS_DATA,
      asyncCall: () => {
        return postApplicantDataApi(data, step);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const postPreEmploymentQuestions =
  (data: PreEmploymentQuestionsData, step: number, cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.POST_PRE_EMPLOYMENT_DATA,
      asyncCall: () => {
        return postApplicantDataApi(data, step);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getEmailList = (cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_EMAIL_LIST,
    asyncCall: () => {
      return getEmailListApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const submitApplicationData =
  (data: ApplicationData, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.SUBMIT_CANDIDATE_APPLICATION,
      asyncCall: () => {
        return submitApplicationApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const updateCandidateEmail =
  (email: string, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_CANDIDATE_EMAIL,
      asyncCall: () => {
        return updateEmailApi(email);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const postCompanyManually =
  (data: CompanyData, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.UPDATE_CARDS,
      asyncCall: () => {
        return postCompanyManuallyApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getProviderList =
  (search: string = "", cb?: any) =>
  (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_PROVIDER_LIST,
      asyncCall: async () => {
        return getProviderListApi(search);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getCards = (cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_CARDS,
    asyncCall: async () => {
      return getCardsApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const addCard = (card: CardDetails) => (dispatch: Dispatch) => {
  dispatch({ type: Types.ADD_CARD, payload: { card }, isAsyncCall: false });
};

export const removeCard = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: Types.REMOVE_CARD, payload: { id }, isAsyncCall: false });
};
export const postIrsData =
  (data: IrsForm, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.POST_IRS_DATA,
      asyncCall: () => {
        return postIrsDataApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getIrsData = (cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_IRS_DATA,
    asyncCall: () => {
      return getIrsDataApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const getPdf = (cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_PDF,
    asyncCall: () => {
      return getPdfApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const postIrsSign = (cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.POST_IRS_SIGN,
    asyncCall: () => {
      return postIrsSignApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const postIrsSignData =
  (data: any, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: data,
      type: Types.POST_IRS_SIGN_DATA,
      asyncCall: () => {
        return postIrsSignDataApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
