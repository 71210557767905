import React from "react";
import { CancelIcon } from "src/assets/icons/index";
import Text from "../Text/Text";
import classes from "./FileUploadContainer.module.scss";
import { Props } from "./types";

const FileUploadContainer: React.FC<Props> = (Props) => {
  const { fileName, onDelete } = Props;
  return (
    <div className={classes.cardContainer}>
      <div className={classes.leftSection}>
        <Text size={20} fontWeight="semibold">
          {fileName}
        </Text>
      </div>
      <div className={classes.rightSection} onClick={onDelete}>
        <div>
          <CancelIcon />
        </div>
      </div>
    </div>
  );
};

export default FileUploadContainer;
