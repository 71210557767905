export const candidateOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const preEmploymentOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const disabilityOptions = [
  {
    label: "Yes, I have a disability, or have had one in the past  ",
    value: "yes",
  },
  {
    label: "No, I do not have a disability and have not had one in the past",
    value: "no",
  },
  { label: "Do not want to answer", value: "do not want to answer" },
];

export const veteranOptions = [
  {
    label:
      "I identify as one or more of the classifications of protected veteran listed above",
    value: "yes",
  },
  { label: "I am not a protected veteran", value: "no" },
  { label: "Do not want to answer", value: "do not want to answer" },
];

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Prefer Not to Say", value: "prefer not to say" },
];

export const raceOptions = [
  {
    label: "American Indian or Alaska Native",
    value: "american indian or alaska native",
  },
  { label: "Asian", value: "asian" },
  { label: "Black or African American", value: "black or african american" },
  {
    label: "Middle Eastern or North African",
    value: "middle eastern or north african",
  },
  {
    label: "Native Hawaiian or other Pacific Islander",
    value: "native hawaiian or other pacific islander",
  },
  { label: "White", value: "white" },
  { label: "Some other race", value: "some other race" },
];

type FieldNameProps =
  | "age_18"
  | "eligible_to_work"
  | "h1b_visa"
  | "dish_net"
  | "dish_net_relatives";

export const questions: {
  name: FieldNameProps;
  question: string;
  required: boolean;
}[] = [
  {
    name: "age_18",
    question: "Are you 18 years of age or older?",
    required: true,
  },
  {
    name: "eligible_to_work",
    question:
      "If hired, can you provide proof that you are eligible to work in the U.S?",
    required: true,
  },
  {
    name: "h1b_visa",
    question:
      "Will you now, or in the future, require sponsorship for employment visa status (e.g. H-1B visa status)",
    required: true,
  },
  {
    name: "dish_net",
    question:
      "Have you ever worked for DISH Network L.L.C., EchoStar Corporation, Blockbuster L.L.C. or any other affiliated company?",
    required: true,
  },
  {
    name: "dish_net_relatives",
    question:
      "Do you currently have any relatives working for any of the companies mentioned in the previous question?",
    required: true,
  },
];

export const signatureLabel =
  "Checking the checkbox is equivalent to a handwritten signature";

export const disabilityPoints = [
  "Alcohol or other substance use disorder (not currently using drugs illegally)",
  "Autoimmune disorder, for example, lupus, fibromyalgia, rheumatoid arthritis, HIV/AIDS",
  "Blind or low vision",
  "Cancer (past or present)",
  "Cardiovascular or heart disease",
  "Celiac disease",
  "Cerebral palsy",
  "Deaf or serious difficulty hearing",
  "Diabetes",
  "Disfigurement, for example, disfigurement caused by burns, wounds, accidents, or congenital disorders",
  "Epilepsy or other seizure disorder",
  "Gastrointestinal disorders, for example, Crohn's Disease, irritable bowel syndrome",
  "Intellectual or developmental disability",
  "Mental health conditions, for example, depression, bipolar disorder, anxiety disorder, schizophrenia, PTSD",
  "Missing limbs or partially missing limbs",
  "Mobility impairment, benefiting from the use of a wheelchair, scooter, walker, leg brace(s) and/or other supports",
  "Nervous system condition, for example, migraine headaches, Parkinson’s disease, multiple sclerosis (MS)",
  "Neurodivergence, for example, attention-deficit/hyperactivity disorder (ADHD), autism spectrum disorder, dyslexia, dyspraxia, other learning disabilities",
  "Partial or complete paralysis (any cause)",
  "Pulmonary or respiratory conditions, for example, tuberculosis, asthma, emphysema",
  "Short stature (dwarfism)",
  "Traumatic brain injury",
];
