import React from "react";
import classes from "./ProgressBar.module.scss";

type Props = {
  completed: string;
};

const ProgressBar: React.FC<Props> = ({ completed }) => {
  return (
    <div className={classes.container}>
      <div className={classes.filler} style={{ width: completed }}></div>
    </div>
  );
};

export default ProgressBar;
