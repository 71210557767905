import * as Yup from "yup";
import { phoneRegExp, tinRegex } from "src/utils/helper";

export type FormProps = {
  first_name: string;
  middle_name: string;
  last_name: string;
  taxpayer_id: string;
  prev_first_name: string;
  prev_middle_name: string;
  prev_last_name: string;
  spouse_first_name: string;
  spouse_middle_name: string;
  spouse_last_name: string;
  spouse_taxpayer_id: string;
  prev_spouse_first_name: string;
  prev_spouse_middle_name: string;
  prev_spouse_last_name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  prev_address: string;
  prev_city: string;
  prev_state: string;
  prev_zip: string;
  ives_name: string;
  ives_id: string;
  sor_mbx_id: string;
  ives_address: string;
  ives_city: string;
  ives_state: string;
  ives_zip: string;
  customer_file_nr: string;
  unique_id: string;
  client_name: string;
  client_phone: string;
  client_address: string;
  client_city: string;
  client_state: string;
  client_zip: string;
  transcript_req: string;
  transcript_type: string;
  wage_transcript: boolean;
  wage_forms_1: string;
  wage_forms_2: string;
  wage_forms_3: string;
  taxpayer_line_1a: boolean;
  taxpayer_line_2a: boolean;
  tax_year_1: string | undefined;
  tax_year_2: string | undefined;
  tax_year_3: string | undefined;
  tax_year_4: string | undefined;
  signatory: boolean;
  signature_1a: string;
  signature_1a_date: string;
  signature_1a_phone: string;
  form_4506c: boolean;
  signatory_signed: boolean;
  print_name: string;
  estate_title: string;
  spouse_signature: string;
  spouse_signature_date: string;
  spouse_form_4506c: boolean;
  spouse_signatory_signed: boolean;
  spouse_print_name: string;
};

export const formSchema = Yup.object().shape({
  first_name: Yup.string().trim(),
  middle_name: Yup.string().trim(),
  last_name: Yup.string().trim(),
  taxpayer_id: Yup.string().matches(tinRegex, "Invalid number").trim(),
  prev_first_name: Yup.string().trim(),
  prev_middle_name: Yup.string().trim(),
  prev_last_name: Yup.string().trim(),
  spouse_first_name: Yup.string().trim(),
  spouse_middle_name: Yup.string().trim(),
  spouse_last_name: Yup.string().trim(),
  spouse_taxpayer_id: Yup.string().matches(tinRegex, "Invalid number").trim(),
  prev_spouse_first_name: Yup.string().trim(),
  prev_spouse_middle_name: Yup.string().trim(),
  prev_spouse_last_name: Yup.string().trim(),
  address: Yup.string().trim(),
  city: Yup.string().trim(),
  state: Yup.string()
    .length(2, "State code must be only 2 characters")
    .matches(/^[A-Z]+$/, "State code must be uppercase letters")

    .trim(),
  zip: Yup.string()
    .matches(/^\d{5}$/, "Invalid zip code")

    .trim(),
  prev_address: Yup.string().trim(),
  prev_city: Yup.string().trim(),
  prev_state: Yup.string()
    .length(2, "State code must be only 2 characters")
    .matches(/^[A-Z]+$/, "State code must be uppercase letters")
    .trim(),
  prev_zip: Yup.string()
    .matches(/^\d{5}$/, "Invalid zip code")
    .trim(),
  ives_name: Yup.string().trim(),
  ives_id: Yup.string().trim(),
  sor_mbx_id: Yup.string().trim(),
  ives_address: Yup.string().trim(),
  ives_city: Yup.string().trim(),
  ives_state: Yup.string()
    .length(2, "State code must be only 2 characters")
    .matches(/^[A-Z]+$/, "State code must be uppercase letters")
    .trim(),
  ives_zip: Yup.string()
    .matches(/^\d{5}$/, "Invalid zip code")
    .trim(),
  customer_file_nr: Yup.string().trim(),
  unique_id: Yup.string().trim(),
  client_name: Yup.string().trim(),
  client_phone: Yup.string()
    .matches(phoneRegExp, "Enter valid phone number")
    .trim(),
  client_address: Yup.string().trim(),
  client_city: Yup.string().trim(),
  client_state: Yup.string()
    .length(2, "State code must be only 2 characters")
    .matches(/^[A-Z]+$/, "State code must be uppercase letters")
    .trim(),
  client_zip: Yup.string()
    .matches(/^\d{5}$/, "Invalid zip code")
    .trim(),
  transcript_req: Yup.string().trim(),
  transcript_type: Yup.string().trim(),
  wage_transcript: Yup.boolean(),
  wage_forms_1: Yup.string().trim(),
  wage_forms_2: Yup.string().trim(),
  wage_forms_3: Yup.string().trim(),
  taxpayer_line_1a: Yup.boolean(),
  taxpayer_line_2a: Yup.boolean(),
  tax_year_1: Yup.string(),
  tax_year_2: Yup.string(),
  tax_year_3: Yup.string(),
  tax_year_4: Yup.string(),
  signatory: Yup.boolean(),
  signature_1a: Yup.string().trim(),
  signature_1a_date: Yup.string(),
  signature_1a_phone: Yup.string()
    .trim()
    .matches(phoneRegExp, "Enter valid phone number")
    .min(10, "Enter valid phone number")
    .max(10, "Enter valid phone number"),
  form_4506c: Yup.boolean(),
  signatory_signed: Yup.boolean(),
  print_name: Yup.string().trim(),
  estate_title: Yup.string().trim(),
  spouse_signature: Yup.string().trim(),
  spouse_signature_date: Yup.string().trim(),
  spouse_form_4506c: Yup.boolean(),
  spouse_signatory_signed: Yup.boolean(),
  spouse_print_name: Yup.string().trim(),
});
