import cx from "classnames";
import React, { useRef } from "react";
import { UploadIcon } from "src/assets/icons";
import classes from "./FileUpload.module.scss";
import { Props } from "./types";

const FileUpload: React.FC<Props> = ({
  label = "",
  onChange,
  className = "",
  error = "",
  ...rest
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <div
        className={cx(classes.uploadButtonWrapper, className)}
        onClick={handleUploadClick}
      >
        <div className={cx(classes.uploadButton)}>
          <div className={classes.leftContainer}>
            <UploadIcon height={24} width={24} />
            <label>{label}</label>
          </div>
          <div className={classes.rightContainer}>
            <UploadIcon height={16} width={16} />
            <input
              ref={fileInputRef}
              type="file"
              onChange={onChange}
              {...rest}
            />
            <label>Upload</label>
          </div>
        </div>
      </div>
      {error && <div className={classes.invalidFeedback}>{error}</div>}
    </>
  );
};

export default FileUpload;
