import cn from "classnames";
import React, { useState } from "react";
import { CancelIcon } from "src/assets/icons/index";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import Badge from "../Badge";
import classes from "./Card.module.scss";
import { Props } from "./types";
const Card: React.FC<Props> = (Props) => {
  const [showManualOption, setShowManualOption] = useState(false);
  const { type, companyName, verified, handleClickCard, id, source } = Props;
  const { removeCard } = useActions();

  const isManual = source === "manual";
  const isManualVerified = isManual && verified !== undefined;
  const isVerified = verified === 1 || isManualVerified;
  const handleDelete = () => {
    if (isVerified) {
      return;
    }
    removeCard(id);
  };

  const renderRightSection = () => {
    switch (true) {
      case isVerified && source === "manual":
        return <Badge>Job Details Added</Badge>;
      case isVerified:
        return <Badge type="success">Verified by EV3</Badge>;
      default:
        return (
          <div>
            <CancelIcon />
          </div>
        );
    }
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.upperSection}>
        <Text fontWeight="semibold" size={20}>
          {companyName}
        </Text>
        <div onClick={handleDelete} className={classes.upperLeftSection}>
          {renderRightSection()}
        </div>
      </div>
      {!isManualVerified && !isVerified && (
        <div
          className={cn(classes.lowerSection, {
            [classes.manual]: true,
          })}
        >
          {!isVerified && (
            <Text
              onClick={() => {
                handleClickCard({
                  tv_type: type,
                  tv_name: companyName,
                  tv_id: id,
                  tv_source: source,
                  needsManualVerification: false,
                });
                setTimeout(() => {
                  setShowManualOption(true);
                }, 5000);
              }}
              color="#2833B2"
              fontFamily="montserrat"
              fontWeight="medium"
              style={{ cursor: "pointer" }}
              size={13}
            >
              {isManual ? "Tap to Provide Details" : "Tap to Verify"}
            </Text>
          )}
          {showManualOption && !isManual && !isVerified && (
            <Text
              onClick={() =>
                handleClickCard({
                  tv_type: type,
                  tv_name: companyName,
                  tv_id: id,
                  tv_source: source,
                  needsManualVerification: !isManual && !isVerified,
                })
              }
              color="#2833B2"
              fontFamily="montserrat"
              fontWeight="medium"
              style={{ cursor: "pointer" }}
              size={13}
            >
              Manually Verify
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
