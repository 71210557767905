import React from "react";
import Button from "src/components/Button";
import Text from "src/components/Text";
import classes from "./DepartmentSummary.module.scss";

type Props = {
  handleNext: () => void;
  buttonText: string;
};
const DepartmentSummary: React.FC<Props> = ({ handleNext, buttonText }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Text size={24} fontWeight="semibold">
          An Equal Opportunity Employee basis of race, national origin, gender,
          veteran status, disability, age, religion, or any other
          characteristics protected by federal or applicable state law
        </Text>
        <Text>*required field.</Text>
      </div>
      <div className={classes.details}>
        <Text>
          As a reminder, our system will send you an email confirmation within
          10 minutes of completing your application. If you do not receive the
          email, please make sure to check your spam or junk email box and add
          us to your safe sender list.
        </Text>
        <Text>
          Thank you, your application is now complete and will be reviewed by a
          member of the recruiting team.
        </Text>
        <Text fontWeight="bold">Department Summary</Text>
        <Text className={classes.departmentSummary}>
          DISH and Boost Infinite are upending the wireless industry and
          unseating the entrenched incumbent carriers.
          <br /> We are building America's first cloud-native 5G network to
          transform the way we live, work and play with unlimited potential. Our
          teams operate at the intersection of wireless, data analytics, Al and
          the cloud to create something state-of-the-art, radically original and
          truly unlike what anyone else can. Boost Infinite will become the
          fourth-largest wireless carrier and change the way the world
          communicates. <br />
          We are driven by curiosity, pride, adventure, and a desire to win -
          it's in our DNA. We're looking for people with boundless energy,
          intelligence, and an overwhelming need-to-achieve to join our team as
          we embark on the next chapter of our story.
          <br /> Opportunity is here. We are DISH.
          <br /> Job Duties and Responsibilities <br />
          The Customer Experience Operations Specialist specializes in one of
          our lines of business, and while servicing our customers, serves as an
          expert to create recommendations on agent tools and other processes,
          test new processes, and provides feedback to skill owners, corporate
          operations managers, executives, and field leadership in order to
          improve customer and agent experience.
          <br /> Tasks and responsibilities The Customer Experience Operations
          Specialist will create best-in-class experiences for our Boost
          Infinite customers via voice, chat and email and utilize this
          experience for the following:
        </Text>
        <div>
          <ul>
            <li>Observation</li>
            <li> Document tool behavior</li>
            <li>
              Listen/read recorded or live customer interactions to gain
              additional insights on the outcomes of calls/chats from our
              general agent population
            </li>
            <li> Incubation </li>
            <li>Test changes to flows, or any other tools</li>
          </ul>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          onClick={handleNext}
          // disabled={!isValid || !dirty}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default DepartmentSummary;
