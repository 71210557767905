/* eslint-disable @typescript-eslint/no-explicit-any */
import TruvBridge from "@truv/react";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { CardDetails, CardType } from "src/components/Card/types";
import CustomDialog from "src/components/Dialog";
import ErrorMessage from "src/components/ErrorMessage";
import FullPageLoader from "src/components/FullPageLoader";
import Search from "src/components/Search";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import useData from "src/utils/useData";
import classes from "./Experience.module.scss";
import JobDetailsForm from "./components/JobDetails";

type Props = {
  handleNext: () => void;
  buttonText: string;
};

const Experience: React.FC<Props> = ({ buttonText, handleNext }) => {
  const [addExperienceFlow, setAddExperienceFlow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [type, setType] = useState<CardType>("company");
  const [currentCard, setCurrentCard] = useState<CardDetails>({
    tv_id: "",
    tv_name: "",
    tv_type: "company",
    tv_source: "",
    needsManualVerification: false,
  });
  const navigate = useNavigate();
  const [bridgeToken, setBridgeToken] = useState<string>("");
  const [manuallyAddExperienceFlow, setManuallyAddExperienceFlow] =
    useState<boolean>(false);
  const {
    getCompanyList,
    getBridgeToken,
    verifyEmployment,
    getProviderList,
    addCard,
    getCards,
  } = useActions();
  const {
    onboarding: {
      companyList: { data: companies, loading: companyLoading },
      providerList: { data: providers, loading: providerLoading },
      getBridgeToken: {
        loading: getBridgeTokenLoader,
        error: getBridgeTokenError,
      },
      cards: { data: companyCards, loading: cardsLoading },
    },
  } = useData();

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCompanyList();
    getProviderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExperienceFlow]);

  const handleClickCard = async (card: CardDetails) => {
    setCurrentCard(card);
    setType(card.tv_type);

    if (card.tv_source === "manual" || card.needsManualVerification) {
      handleManuallyAddJobDetails();
      return;
    }
    const resp: any = await getBridgeToken(card.tv_id, type);
    if (resp.success) {
      handleBridgeToken(resp.data.bridge_token);
    }
  };

  const handleManuallyAddJobDetails = () => {
    if (manuallyAddExperienceFlow) {
      handleBridgeToken();
    }
    setManuallyAddExperienceFlow((prevState) => !prevState);
  };

  const handleBridgeToken = (value?: string) => {
    setBridgeToken(value || "");
  };

  const handleType = (type: CardType) => {
    setType(type);
  };

  const handleJobData = (card: CardDetails) => {
    addCard(card);
    setAddExperienceFlow(false);
  };

  const handleSuccess = async (publicToken: string) => {
    await verifyEmployment({
      public_token: publicToken,
      name: currentCard.tv_name,
      [type === "provider" ? "provider_id" : "company_mapping_id"]:
        currentCard.tv_id,
    });
    setBridgeToken("");
  };

  const handleCancel = () => {
    setType("company");
    setAddExperienceFlow(false);
  };

  const handleSearchChange = debounce((value: string) => {
    if (type === "provider") {
      getProviderList(value);
    } else {
      getCompanyList(value);
    }
  }, 500);

  const filteredCards = companyCards?.filter((card) => !!card?.tv_name);

  const isAllCardsNotVerified = filteredCards?.some((card) => {
    if (card.tv_source === "manual") {
      return card.verified !== 0;
    }
    return card?.verified !== 1;
  });

  const isForIrsVerification = filteredCards?.some((card) => {
    return card?.tv_source !== "manual";
  });

  return (
    <>
      {(getBridgeTokenLoader || cardsLoading) && <FullPageLoader />}
      <div className={classes.experienceWrapper}>
        {manuallyAddExperienceFlow ? (
          <JobDetailsForm
            handleType={handleType}
            handleManually={handleManuallyAddJobDetails}
            cardId={currentCard.tv_id}
          />
        ) : (
          <>
            <div className={classes.companyCardWrapper}>
              {filteredCards?.map((card: CardDetails) => (
                <div key={card.tv_name} className={classes.companyCard}>
                  <Card
                    id={card.tv_id}
                    companyName={card.tv_name}
                    type={card.tv_type}
                    verified={card.verified}
                    source={card.tv_source}
                    handleClickCard={handleClickCard}
                  />
                </div>
              ))}
              {!addExperienceFlow ? (
                companyCards?.length > 0 ? (
                  <div>
                    <Button
                      variant="outline"
                      onClick={() => setAddExperienceFlow(true)}
                    >
                      Add Another
                    </Button>
                    <Text className={classes.guidanceText}>
                      If you want to add another employer, tap the ‘Add Another’
                      button above.
                    </Text>
                  </div>
                ) : (
                  <div>
                    <Button
                      variant="outline"
                      onClick={() => setAddExperienceFlow(true)}
                    >
                      Add
                    </Button>
                    <Text className={classes.guidanceText}>
                      Please tap the ‘Add’ button above to add your current or
                      previous employers.
                    </Text>
                  </div>
                )
              ) : (
                <Search
                  searchList={type === "provider" ? providers : companies}
                  handleClick={handleJobData}
                  type={type}
                  handleType={handleType}
                  handleCancel={handleCancel}
                  selectedCards={companyCards}
                  handleChange={handleSearchChange}
                  companyLoading={companyLoading}
                  providerLoading={providerLoading}
                />
              )}
            </div>
            {!!bridgeToken && (
              <TruvBridge
                key={bridgeToken} // to open modal multiple time.because onclose calls directly after opening 2nd time.
                bridgeToken={bridgeToken}
                onSuccess={async (publicToken: string) => {
                  await handleSuccess(publicToken);
                }}
                isOpened={!!bridgeToken}
              />
            )}
            {getBridgeTokenError && (
              <ErrorMessage errorMessage={getBridgeTokenError} />
            )}
            {showDialog && (
              <CustomDialog
                showDialog={showDialog}
                onClose={() => setShowDialog(false)}
                description="Please make sure to verify your providers above."
              />
            )}
            <div className={classes.buttonWrapper}>
              <Button
                disabled={filteredCards?.length === 0}
                onClick={() => {
                  if (isAllCardsNotVerified) {
                    setShowDialog(true);
                    return;
                  }
                  if (isForIrsVerification) {
                    handleNext();
                    return;
                  }
                  navigate("/onboarding/forms/1");
                }}
              >
                {buttonText}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Experience;
