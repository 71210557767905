import cx from "classnames";
import React, { CSSProperties } from "react";
import classes from "./Text.module.scss";
import { Props } from "./types";

const Text: React.FC<Props> = ({
  size = 16,
  fontWeight = "regular",
  fontFamily = "roboto",
  children,
  className = "",
  color = "black",
  style = {},
  required,
  ...rest
}) => {
  return (
    <p
      style={
        {
          "--size": `${size}px`,
          "--color": `${color}`,
          ...style,
        } as CSSProperties
      }
      className={cx(
        classes.typography,
        classes[fontFamily],
        classes[fontWeight],
        {
          [className]: className,
        },
      )}
      {...rest}
    >
      {children}
      {required && <span>*</span>}
    </p>
  );
};

export default Text;
