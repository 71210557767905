import cx from "classnames";
import React from "react";
import classes from "./CheckBox.module.scss";
import { Props } from "./types";

const CheckBox: React.FC<Props> = (Props) => {
  const {
    checkBoxClassName = "",
    labelClassName = "",
    wrapperClassName = "",
    disabled = false,
    error = "",
    label,
    id,
    boldLabel = "",
    checked = false,
    touched = false,
    required = false,
    onChange,
    ...rest
  } = Props;

  return (
    <>
      <div className={cx(classes.checkboxStyling, wrapperClassName)}>
        <input
          className={cx({
            [checkBoxClassName]: checkBoxClassName,
          })}
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          {...rest}
        />

        {label && (
          <label
            className={cx({
              [labelClassName]: labelClassName,
            })}
            htmlFor={id}
          >
            {boldLabel && (
              <span style={{ fontWeight: "700" }}>{boldLabel}</span>
            )}{" "}
            {label}
            {required && <span className={classes.requiredLabel}>*</span>}
          </label>
        )}
      </div>

      {touched && error && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </>
  );
};

export default CheckBox;
