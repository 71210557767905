/* eslint-disable @typescript-eslint/no-explicit-any */
import { Viewer } from "@react-pdf-viewer/core";
import React, { useEffect, useState } from "react";
import Button from "src/components/Button";
import Drawer from "src/components/Drawer/Drawer";
import FullPageLoader from "src/components/FullPageLoader";
import { useActions } from "src/store/actions";
import useData from "src/utils/useData";
import IRSForm from "../Forms/components/IRSForm";
import IRSVerificationForm from "../IRSVerificationForm";
import classes from "./PreviewFIle.module.scss";
// eslint-disable-next-line no-restricted-imports
import "@react-pdf-viewer/core/lib/styles/index.css";

const PreviewFile = ({
  buttonText,
  handleNext,
}: {
  buttonText: string;
  handleNext: () => void;
}) => {
  const [pdfData, setPdfData] = useState<any>();
  const { getPdf, postIrsSign } = useActions();
  const [isOpen, setIsOpen] = useState(false);
  const [showIrsSign, setShowIrsSign] = useState(false);

  const {
    onboarding: {
      getPdf: { loading },
      postIrsSign: { loading: irsSignLoader },
    },
  } = useData();

  useEffect(() => {
    fetchIRSFormPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchIRSFormPDF = async () => {
    const response: any = await getPdf();
    const dataUrl = URL.createObjectURL(response.data);
    setPdfData(dataUrl);
  };

  const handleSignForm = async () => {
    const resp: any = await postIrsSign();
    if (resp?.success) {
      setShowIrsSign(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    fetchIRSFormPDF();
  };

  return showIrsSign ? (
    <IRSVerificationForm handleNext={handleNext} />
  ) : (
    <div className={classes.previewFileWrapper}>
      <div>
        {loading ? (
          <FullPageLoader />
        ) : (
          <>
            {pdfData && (
              <div className={classes.pdfViewer}>
                <Viewer fileUrl={pdfData} defaultScale={1.5} />
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.footer}>
        <Button variant="outline" onClick={() => setIsOpen(true)}>
          Edit Form
        </Button>
        <Button onClick={handleSignForm} loading={irsSignLoader}>
          {buttonText}
        </Button>
      </div>
      {isOpen && (
        <Drawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          className={classes.drawer}
        >
          <IRSForm handleNext={handleClose} />
        </Drawer>
      )}
    </div>
  );
};

export default PreviewFile;
