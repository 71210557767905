/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from "classnames";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import * as Yup from "yup";
import { CalenderIcon } from "src/assets/icons";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import Input from "src/components/Input";
import { useActions } from "src/store/actions";
import { phoneRegExp } from "src/utils/helper";
import useData from "src/utils/useData";
import classes from "./IRSVerificationForm.module.scss";

type FormProps = {
  signatory: boolean;
  signature_1a: string;
  signature_1a_date: string;
  signature_1a_phone: string;
  form_4506c: boolean;
  signatory_signed: boolean;
  print_name: string;
};
type Props = {
  handleNext: () => void;
};
const IRSVerificationForm: React.FC<Props> = ({ handleNext }) => {
  const [loading, setLoading] = useState(false);
  const { postIrsData, getIrsData } = useActions();
  const {
    onboarding: {
      irsData: { data },
    },
  } = useData();
  const {
    signatory,
    signature_1a,
    signature_1a_date,
    signature_1a_phone,
    form_4506c,
    signatory_signed,
    print_name,
  } = data;

  useEffect(() => {
    getIrsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initialValues = {
    signatory: signatory ? true : false,
    signature_1a: signature_1a || "",
    signature_1a_date: signature_1a_date || new Date().toISOString(),
    signature_1a_phone: signature_1a_phone || "",
    form_4506c: form_4506c ? true : false,
    signatory_signed: signatory_signed ? true : false,
    print_name: print_name || "",
  };
  const formSchema = Yup.object().shape({
    signatory: Yup.boolean().oneOf([true], "Please fill out required fields"),
    signature_1a: Yup.string()
      .required("Please fill out required fields")
      .trim(),
    signature_1a_date: Yup.string().required("Please fill out required fields"),
    signature_1a_phone: Yup.string()
      .required("Please fill out required fields")
      .trim()
      .matches(phoneRegExp, "Enter valid phone number")
      .min(10, "Enter valid phone number")
      .max(10, "Enter valid phone number"),
    form_4506c: Yup.boolean().oneOf([true], "Please fill out required fields"),
    signatory_signed: Yup.boolean().oneOf(
      [true],
      "Please fill out required fields",
    ),
    print_name: Yup.string().required("Please fill out required fields").trim(),
  });
  return (
    <div className={classes.formWrapper}>
      <Formik
        initialValues={initialValues}
        validateOnMount
        validationSchema={formSchema}
        initialTouched={{ signature_1a_phone: true }}
        onSubmit={async (values: FormProps) => {
          setLoading(true);
          const resp: any = await postIrsData({
            ...values,
            signatory: values.signatory ? 1 : 0,
            form_4506c: values.form_4506c ? 1 : 0,
            signatory_signed: values.signatory_signed ? 1 : 0,
          });
          setLoading(false);
          if (resp?.success) handleNext();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          isValid,
        }: FormikProps<FormProps>) => {
          return (
            <Form>
              <div className={classes.marginBottom}>
                <div
                  className={cn(classes.multiInputWrapper, classes.noMargin)}
                >
                  <div className={classes.multiInput}>
                    <CheckBox
                      required={true}
                      checked={values.signatory}
                      boldLabel="Signatory"
                      label="attests that he/she has read the attestation clause and upon so reading declares that he/she has the authority to sign the Form 4506-C. (See instructions.)"
                      id="signatory"
                      name="signatory"
                      onChange={handleChange}
                      wrapperClassName={cn(
                        classes.signCheckBox,
                        classes.noMargin,
                      )}
                    />
                  </div>
                </div>
                {touched.signatory && errors.signatory && (
                  <div className={classes.invalidFeedback}>
                    {errors.signatory}
                  </div>
                )}
              </div>
              <div className={classes.inputWrapper}>
                <label className={classes.inputLabel}>
                  <span>Caution:</span>
                  <br /> Do not sign this form unless all applicable lines have
                  been completed.
                </label>
              </div>
              <div className={classes.inputWrapper}>
                <label className={classes.inputLabel}>
                  <span>Signature of taxpayer(s).</span>
                  <br /> I declare that I am either the taxpayer whose name is
                  shown on line 1a or, if applicable, line 2a, or a person
                  authorized to obtain the tax information requested. If the
                  request applies to a joint return, at least one spouse must
                  sign; however, if both spouses' names and TINs are listed in
                  lines 1a-1b and 2a-2b, both spouses must sign the request. If
                  signed by a corporate officer, 1 percent or more shareholder,
                  partner, managing member, guardian, tax matters partner,
                  executor, receiver, administrator, trustee, or party other
                  than the taxpayer, I certify that I have the authority to
                  execute Form 4506-C on behalf of the taxpayer. Note: This form
                  must be received by IRS within 120 days of the signature date.
                </label>
              </div>
              <div className={cn(classes.inputWrapper, classes.marginBottom)}>
                <label className={cn(classes.inputLabel, classes.marginBottom)}>
                  <span style={{ fontSize: "18px" }}>Sign Here</span>
                </label>
                <Input
                  required={true}
                  label="Signature for Line 1a"
                  placeholder="Enter Here"
                  name="signature_1a"
                  id="signature_1a"
                  value={values.signature_1a}
                  touched={touched.signature_1a}
                  error={errors.signature_1a}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div
                  style={{
                    marginBottom: `${
                      touched.signature_1a_date && errors.signature_1a_date
                        ? "8px"
                        : "16px"
                    }`,
                  }}
                >
                  <label className={cn(classes.inputLabel)}>
                    Date<span style={{ marginLeft: "3px" }}>*</span>
                  </label>
                  <Field name="signature_1a_date">
                    {({ field, form }: FieldProps) => {
                      return (
                        <ReactDatePicker
                          icon={<CalenderIcon />}
                          showIcon={true}
                          autoComplete="off"
                          placeholderText="Select"
                          calendarIconClassname={classes.calenderIcon}
                          wrapperClassName={classes.datePickerWrapper}
                          className={cn(classes.datePicker, {
                            [classes.isInvalid]:
                              touched.signature_1a_date &&
                              errors.signature_1a_date,
                          })}
                          dateFormat="MM/dd/yyyy"
                          selected={
                            field.value ? new Date(field.value) : new Date()
                          }
                          onBlur={handleBlur}
                          name="signature_1a_date"
                          onChange={(date) => {
                            form.setFieldValue("signature_1a_date", date);
                          }}
                        />
                      );
                    }}
                  </Field>
                  {touched.signature_1a_date && errors.signature_1a_date && (
                    <div className={classes.invalidFeedback}>
                      {errors.signature_1a_date}
                    </div>
                  )}
                </div>
                <Input
                  required={true}
                  label="Phone number of taxpayer on line 1a or 2a"
                  placeholder="Enter phone number"
                  name="signature_1a_phone"
                  id="signature_1a_phone"
                  value={values.signature_1a_phone}
                  touched={touched.signature_1a_phone}
                  error={errors.signature_1a_phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={10}
                />
              </div>
              <div className={classes.marginBottom}>
                <div className={classes.multiInputWrapper}>
                  <div className={classes.multiInput}>
                    <CheckBox
                      required={true}
                      labelClassName={classes.checkBoxLabel}
                      checked={values.form_4506c}
                      id="form_4506c"
                      label="Form 4506-C was signed by an Authorized Representative"
                      name="form_4506c"
                      wrapperClassName={classes.inputCheckBox}
                      onChange={handleChange}
                    />
                    <CheckBox
                      required={true}
                      labelClassName={classes.checkBoxLabel}
                      checked={values.signatory_signed}
                      id="signatory_signed"
                      label="Signatory confirms document was electronically signed"
                      name="signatory_signed"
                      wrapperClassName={classes.inputCheckBox}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {((touched.form_4506c && errors.form_4506c) ||
                  (touched.signatory_signed && errors.signatory_signed)) && (
                  <div className={classes.invalidFeedback}>
                    {errors.form_4506c || errors.signatory_signed}
                  </div>
                )}
              </div>
              <Input
                required={true}
                label="Print/Type Name"
                placeholder="Enter Here"
                name="print_name"
                id="print_name"
                value={values.print_name}
                touched={touched.print_name}
                error={errors.print_name}
                onChange={handleChange}
                onBlur={handleBlur}
                labelClassName={classes.boldLabel}
              />
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  disabled={!isValid || loading}
                  loading={loading}
                >
                  Finish
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default IRSVerificationForm;
