import Onboarding from "src/pages/Onboarding";
import Intro from "./pages/Onboarding/Intro";
import JobDesc from "./pages/Onboarding/components/JobDesc";

export const publicRoutes = [
  {
    path: "/jobs/:id",
    name: "Jobs",
    component: JobDesc,
  },
  {
    path: "/intro",
    name: "Intro",
    component: Intro,
  },
];

export const authRoutes = [
  {
    path: "/basic-info",
    name: "Onboarding",
    component: Onboarding,
  },
  {
    path: "/experience",
    name: "Onboarding",
    component: Onboarding,
  },
  {
    path: "authorize-irs-verification",
    name: "Onboarding",
    component: Onboarding,
  },
  {
    path: "/update-email",
    name: "Onboarding",
    component: Onboarding,
  },
  {
    path: "/forms",
    name: "Onboarding",
    component: Onboarding,
  },
  {
    path: "/forms/:step",
    name: "Onboarding",
    component: Onboarding,
  },
  {
    path: "/submit",
    name: "Onboarding",
    component: Onboarding,
  },
  {
    path: "/success",
    name: "Onboarding",
    component: Onboarding,
  },
];

export const privateRoutes = [
  {
    path: "/onboarding",
    name: "Onboarding",
    component: Onboarding, //we can add permission for different roles
  },
];
