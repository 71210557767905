import cn from "classnames";
import React from "react";
import classes from "./RadioGroup.module.scss";
import { RadioGroupProps } from "./types";

const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  selectedValue,
  onChange,
  radioClassName = "",
  labelClassName = "",
  ...rest
}) => {
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = e.target.value;
    onChange(selectedOption);
  };
  return (
    <div
      className={cn(classes.wrapper, {
        [radioClassName]: radioClassName,
      })}
    >
      {options.map((option, index) => (
        <div className={classes.radioElement} key={index}>
          <input
            type="radio"
            name="radioGroup"
            value={option.value}
            checked={option.value === selectedValue}
            onChange={handleRadioChange}
            id={option.value}
            {...rest}
          />
          <label
            htmlFor={option.value}
            key={index}
            className={cn(classes.label, { [labelClassName]: labelClassName })}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
