/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookie from "js-cookie";
import { Dispatch } from "redux";
import {
  LoginUser,
  doLogin as doLoginApi,
  uploadResume as uploadResumeApi,
} from "src/api/auth";

import Types from "../types/auth";

export const doLogin = (data: LoginUser, cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAsyncCall: true,
    payload: data,
    type: Types.LOG_IN_USER,
    asyncCall: () => {
      return doLoginApi(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      Cookie.set("token", response.data.token);
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const uploadResume =
  (data: FormData, token: string, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: { formData: data },
      type: Types.UPLOAD_RESUME,
      asyncCall: () => {
        return uploadResumeApi(data, token);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const logOutUser = () => (dispatch: Dispatch) => {
  dispatch({
    type: Types.LOG_OUT_USER,
  });
};
