/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import cn from "classnames";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import FullPageLoader from "src/components/FullPageLoader";
import Input from "src/components/Input";
import RadioGroup from "src/components/RadioGroup";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { signatureLabel, veteranOptions } from "src/utils/constants";
import { formattedDate } from "src/utils/helper";
import useData from "src/utils/useData";
import classes from "./VeteranStatus.module.scss";
// eslint-disable-next-line no-restricted-imports
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  handleNext: () => void;
  buttonText: string;
  step: number;
};
type FormProps = {
  vet: string;
  vet_name: string;
  vet_date: string;
  vet_signature: boolean;
};
let finishLater: boolean;

const VeteranStatus: React.FC<Props> = ({ handleNext, buttonText, step }) => {
  const { postVeteranStatusData } = useActions();
  const {
    onboarding: {
      veteranStatus: { loading, data },
    },
  } = useData();

  const { vet, vet_name, vet_date, vet_signature } = data;
  const formSchema = Yup.object().shape({
    vet: Yup.string().required("Please select an option"),
    vet_name: Yup.string().required("This is required").trim(),
    vet_date: Yup.string().required("Please select a date"),
    vet_signature: Yup.boolean().oneOf([true], "You must check the checkBox"),
  });

  const handleFinishLater = async (values: FormProps) => {
    //TODO: for empty string of field ("") BackEnd throws error
    Object.keys(values).forEach((key) => {
      if (
        values[key as "vet" | "vet_name" | "vet_date" | "vet_signature"] === ""
      ) {
        delete values[key as "vet" | "vet_name" | "vet_date" | "vet_signature"];
      }
    });
    finishLater = true;
    const date = formattedDate(values.vet_date);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resp: any = await postVeteranStatusData(
      {
        ...values,
        vet_signature: values.vet_date ? 1 : 0,
        finish_later: finishLater,
        vet_date: date,
      },
      step,
    );
    if (resp?.success) handleNext();
    finishLater = false;
  };

  return (
    <div className={classes.wrapper}>
      <Formik
        validateOnMount
        initialValues={{
          vet: vet || "",
          vet_name: vet_name || "",
          vet_date: vet_date || new Date().toISOString(),
          vet_signature: vet_signature === 1,
        }}
        validationSchema={formSchema}
        onSubmit={async (values: FormProps) => {
          finishLater = false;
          const date = formattedDate(values.vet_date);
          const resp: any = await postVeteranStatusData(
            {
              ...values,
              vet_signature: values.vet_signature ? 1 : 0,
              vet_date: date,
              finish_later: finishLater,
            },
            step,
          );
          if (resp?.success) handleNext();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          isValid,
          errors,
        }: FormikProps<FormProps>) => {
          return (
            <Form>
              {loading && <FullPageLoader />}
              <div className={classes.title}>
                <Text size={24} fontWeight="bold">
                  Voluntary Self-Identification of Veteran Status
                </Text>
                <Text>*required field.</Text>
              </div>
              <div className={classes.details}>
                <Text className={classes.question}>
                  Why are you being asked to complete this form?
                </Text>
                <Text className={classes.info}>
                  <strong>1.</strong> This employer is a Government contractor
                  subject to the Vietnam Era Veterans' Readjustment Assistance
                  Act of 1974, as amended by the Jobs for Veterans Act of 2002,
                  38 U.S.C.
                </Text>
                <div className={classes.info}>
                  4212 (VEVRAA), which requires Government contractors to take
                  affirmative action to employ and advance in employment
                  <Text>(1) disabled veterans.</Text>
                  <Text>(2) recently separated veterans </Text>
                  <Text>
                    (3) active duty wartime or campaign badge veterans
                  </Text>
                  <Text>(4) Armed Forces service medal veterans.</Text>
                </div>
                <div>
                  <Text className={classes.question}>
                    These classifications are defined as follows:
                  </Text>
                  <ul>
                    <li>A "disabled veteran" is one of the following </li>
                    <li>
                      A veteran of the U.S. military, ground, naval or air
                      service who is entitled to compensation (or who but for
                      the receipt of military retired pay would be entitled to
                      compensation) under laws administered by the Secretary of
                      Veterans Affairs;
                    </li>
                  </ul>
                </div>
                <div className={classes.divider}>
                  <div className={classes.wordWithLine}>OR</div>
                </div>
                <ul>
                  <li>
                    A person who was discharged or released from active duty
                    because of a service-connected disability.
                  </li>
                  <li>
                    A "recently separated veteran" means any veteran during the
                    three-year period beginning on the date of such veteran's
                    discharge or release from active duty in the U.S. military,
                    ground, naval, or air service.
                  </li>
                  <li>
                    An "active duty wartime or campaign badge veteran" means a
                    veteran who served on active duty in the U.S. military,
                    ground, naval or air service during a war, or in a campaign
                    or expedition for which a campaign badge has been authorized
                    under the laws administered by the Department of Defense.
                  </li>
                  <li>
                    An "Armed forces service medal veteran" means a veteran who,
                    while serving on active duty in the U.S. military, ground,
                    naval or air service, participated in a United States
                    military operation for which an Armed Forces service medal
                    was awarded pursuant to Executive Order 12985.
                  </li>
                </ul>
                <Text className={classes.info}>
                  Protected veterans may have additional rights under USERRA —
                  the Uniformed Services Employment and Reemployment Rights Act.
                </Text>
                <Text>
                  In particular, if you were absent from employment in order to
                  perform service in the uniformed service, you may be entitled
                  to be reemployed by your employer in the position you would
                  have obtained with reasonable certainty if not for the absence
                  due to service.
                </Text>
                <Text className={classes.info}>
                  For more information, call the U.S. Department of Labor's
                  Veterans Employment and Training Service (VETS), toll-free, at
                  1-866-4-USA-DOL.
                </Text>
                <Text>
                  <strong>2.</strong> If you believe you belong to any of the
                  categories of protected veterans listed above, please indicate
                  by checking the appropriate box below.
                </Text>
                <Text className={classes.info}>
                  As a Government contractor subject to VEVRAA, we request this
                  information in order to measure the effectiveness of the
                  outreach and positive recruitment efforts we undertake
                  pursuant to VEVRAA.
                </Text>
              </div>
              <div className={classes.radioGroup}>
                <RadioGroup
                  options={veteranOptions}
                  selectedValue={values.vet}
                  onChange={(value) => {
                    setFieldValue("vet", value);
                  }}
                  error={errors.vet}
                  touched={touched.vet}
                  name="vet"
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <ol>
                  <li>
                    Submission of this information is voluntary and refusal to
                    provide it will not subject you to any adverse treatment.
                    The information provided will be used only in ways that are
                    not inconsistent with the Vietnam Era Veterans' Readjustment
                    Assistance Act of 1974, as amended.
                  </li>
                  <li>
                    The information you submit will be kept confidential, except
                    that
                  </li>
                  <ol type="i">
                    <li>
                      supervisors and managers may be informed regarding
                      restrictions on the work or duties of disabled veterans,
                      and regarding necessary accommodations;
                    </li>
                    <li>
                      first aid and safety personnel may be informed, when and
                      to the extent appropriate, if you have a condition that
                      might require emergency treatment; and
                    </li>
                    <li>
                      Government officials engaged in enforcing laws
                      administered by the Office of Federal Contract Compliance
                      Programs, or enforcing the Americans with Disabilities
                      Act, may be informed.
                    </li>
                  </ol>
                </ol>
                <div className={classes.inputWrapper}>
                  <Input
                    placeholder="Enter Here "
                    label="Name"
                    required={true}
                    error={errors.vet_name}
                    touched={touched.vet_name}
                    value={values.vet_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="vet_name"
                    id="vet_name"
                  />
                  <div className={classes.datePickerWrapper}>
                    <label className={classes.datePickerLabel}>
                      Today's Date*
                    </label>
                    <Field name="vet_date">
                      {({ field, form }: FieldProps) => (
                        <DatePicker
                          autoComplete="off"
                          placeholderText="Enter Here"
                          className={cn(classes.datePicker, {
                            [classes.isInvalid]:
                              touched.vet_date && errors.vet_date,
                          })}
                          selected={
                            field.value ? new Date(field.value) : new Date()
                          }
                          dateFormat="MMM dd, yyyy"
                          onBlur={handleBlur}
                          name="vet_date"
                          onChange={(date) => {
                            form.setFieldValue("vet_date", date);
                          }}
                        />
                      )}
                    </Field>
                    {touched.vet_date && errors.vet_date && (
                      <div className={classes.invalidFeedback}>
                        {errors.vet_date}
                      </div>
                    )}
                  </div>
                  <CheckBox
                    labelClassName={classes.checkBoxLabel}
                    checked={values.vet_signature}
                    id="vet_signature"
                    label={signatureLabel}
                    touched={touched.vet_signature}
                    error={errors.vet_signature}
                    name="vet_signature"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <Button type="submit" disabled={!isValid || loading}>
                  {buttonText}
                </Button>
                <Button
                  type="button"
                  variant="text"
                  className={classes.finishLater}
                  onClick={() => handleFinishLater(values)}
                >
                  Finish Later
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default VeteranStatus;
