/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Button from "src/components/Button";
import FileUpload from "src/components/FileUpload/FileUpload";
import FileUploadContainer from "src/components/FileUploadContainer";
import FullPageLoader from "src/components/FullPageLoader";
import Input from "src/components/Input";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { emailRegex } from "src/utils/helper";
import useData from "src/utils/useData";
import classes from "./BasicInfo.module.scss";
import { LoginInputProps } from "./types";

type Props = {
  handleNext: () => void;
  buttonText: string;
};
const allowedResumeFormats = [
  "application/pdf",
  "image/jpeg",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/rtf",
  "application/vnd.oasis.opendocument.text",
];
const BasicInfo: React.FC<Props> = ({ handleNext, buttonText }) => {
  const { doLogin, uploadResume } = useActions();
  const {
    auth: {
      user: { data: userData },
      uploadResume: { data: resumeData },
    },
  } = useData();
  const { name, email } = userData;
  const { formData } = resumeData;
  const [loading, setLoading] = useState(false);
  const basicInfoSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required.").trim(),
    email: Yup.string()
      .matches(emailRegex, "Enter valid email address")
      .required("Email address is required.")
      .trim(),
    resume: Yup.mixed()
      .required("Please upload a file")
      .test(
        "fileSize",
        "File size must be less than or equal to 5 MB",
        (value) => {
          if (value instanceof File) {
            const maxSize = 5 * 1024 * 1024; // 5 MB
            return value.size <= maxSize;
          }
          return false;
        },
      )
      .test(
        "fileType",
        "Invalid file format. Only PDF, JPEG, .doc, .docx, .rtf, .odt files are allowed.",
        (value) => {
          if (value instanceof File) {
            return allowedResumeFormats.includes(value.type);
          }
          return false;
        },
      ),
  });

  return (
    <div className={classes.basicInfoWrapper}>
      <Formik
        validateOnMount={true}
        initialValues={{
          name: name || "",
          email: email || "",
          resume: (formData?.get("resume") as File) || null,
        }}
        validationSchema={basicInfoSchema}
        onSubmit={async (value: LoginInputProps) => {
          setLoading(true);
          const loginResp: any = await doLogin({
            name: value.name,
            email: value.email,
          });
          const formData = new FormData();
          formData.append("resume", value.resume as File);
          const token = loginResp?.data?.token as string;
          const uploadResp: any = await uploadResume(formData, token);
          setLoading(false);
          if (uploadResp.success) {
            handleNext();
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          isValid,
        }) => {
          return (
            <>
              {loading && <FullPageLoader />}
              <Form className={classes.form}>
                <Input
                  id="name"
                  name="name"
                  label="Full Name"
                  onBlur={handleBlur}
                  placeholder="Enter Full Name"
                  inputWrapperClassName={classes.input}
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                  touched={touched.name}
                />
                <Input
                  id="email"
                  name="email"
                  label="Email Address"
                  placeholder="Enter Email"
                  inputWrapperClassName={classes.input}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                />
                <FileUpload
                  error={errors.resume}
                  label="Upload Resume"
                  onBlur={handleBlur}
                  touched={touched.resume}
                  name="resume"
                  className={classes.uploadInput}
                  onChange={(event) => {
                    const newFiles = event.currentTarget.files;
                    if (newFiles) {
                      setFieldValue("resume", newFiles[0]);
                    }
                  }}
                  value=""
                />
                {values.resume ? (
                  <div className={classes.fileContainer}>
                    <FileUploadContainer
                      fileName={values.resume.name}
                      onDelete={() => setFieldValue("resume", null)}
                    />
                  </div>
                ) : (
                  <Text className={classes.uploadInfo}>
                    File needs to be one of the following formats: PDF, JPEG,
                    .doc, .docx, .rtf, .odt
                  </Text>
                )}
              </Form>
              <Button
                type="submit"
                onClick={async () => {
                  await handleSubmit();
                }}
                disabled={!isValid || loading}
              >
                {buttonText}
              </Button>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default BasicInfo;
