import { Request } from "./request";

export type CandidateData = {
  age_18?: boolean;
  eligible_to_work?: boolean;
  h1b_visa?: boolean;
  dish_net?: boolean;
  dish_net_relatives?: boolean;
  relatives_name_pos?: string;
  finish_later?: boolean;
  salary_req?: number;
};

export type VoluntaryInformation = {
  race?: string;
  gender?: string;
  finish_later?: boolean;
};
export type DisabilityInformation = {
  disability?: string;
  pbs_name?: string;
  pbs_date?: string;
  pbs_signature?: number;
  finish_later?: boolean;
};
export type VeteranStatus = {
  vet?: string;
  vet_name?: string;
  vet_date?: string;
  vet_signature?: number;
  finish_later?: boolean;
};
export type PreEmploymentQuestionsData = {
  pes_complete?: string;
  pes_el_inv?: string;
  finish_later?: boolean;
};
export type ApplicationData = {
  email?: string;
  receive_updates?: boolean;
  updates_phone?: string;
};
export type CompanyData = {
  company_name?: string;
  job_title?: string;
  start_date?: string;
  end_date?: string;
  tv_id?: string;
};

export type VerifyEmploymentData = {
  public_token: string;
  name: string;
  provider_id?: string;
  company_mapping_id?: string;
};
export type IrsForm = {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  taxpayer_id?: string;
  prev_first_name?: string;
  prev_middle_name?: string;
  prev_last_name?: string;
  spouse_first_name?: string;
  spouse_middle_name?: string;
  spouse_last_name?: string;
  spouse_taxpayer_id?: string;
  prev_spouse_first_name?: string;
  prev_spouse_middle_name?: string;
  prev_spouse_last_name?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  prev_address?: string;
  prev_city?: string;
  prev_state?: string;
  prev_zip?: string;
  ives_name?: string;
  ives_id?: string;
  sor_mbx_id?: string;
  ives_address?: string;
  ives_city?: string;
  ives_state?: string;
  ives_zip?: string;
  customer_file_nr?: string;
  unique_id?: string;
  client_name?: string;
  client_phone?: string;
  client_address?: string;
  client_city?: string;
  client_state?: string;
  client_zip?: string;
  transcript_req?: string;
  transcript_type?: string;
  wage_transcript?: number;
  wage_forms_1?: string;
  wage_forms_2?: string;
  wage_forms_3?: string;
  taxpayer_line_1a?: number;
  taxpayer_line_2a?: number;
  tax_year_1?: string;
  tax_year_2?: string;
  tax_year_3?: string;
  tax_year_4?: string;
  signatory?: number;
  signature_1a?: string;
  signature_1a_date?: string;
  signature_1a_phone?: string;
  form_4506c?: number;
  signatory_signed?: number;
  print_name?: string;
  estate_title?: string;
  spouse_signature?: string;
  spouse_signature_date?: string;
  spouse_form_4506c?: number;
  spouse_signatory_signed?: number;
  spouse_print_name?: string;
};

export const postApplicantData = async (
  data:
    | CandidateData
    | VoluntaryInformation
    | DisabilityInformation
    | VeteranStatus
    | PreEmploymentQuestionsData,
  step?: number,
) => {
  return Request.call({
    url: `api/applicant/step3/${step}`,
    method: "POST",
    data,
  });
};

export const getCompanyList = async (search: string = "") => {
  return Request.call({
    url: `api/applicant/step2/companies`,
    method: "GET",
    params: { q: search || "a" },
  });
};
export const getBridgeToken = async (id: string, type: string) => {
  return Request.call({
    url: `api/truv/bridge_token`,
    method: "POST",
    data: { [type === "provider" ? "provider_id" : "company_mapping_id"]: id },
  });
};

export const verifyEmployment = async (data: VerifyEmploymentData) => {
  return Request.call({
    url: `api/applicant/step2/verify`,
    method: "POST",
    data,
  });
};

export const submitApplication = async (data: ApplicationData) => {
  return Request.call({
    url: `api/applicant/step4`,
    method: "POST",
    data,
  });
};
export const getEmailList = async () => {
  return Request.call({
    url: `api/applicant/step4`,
    method: "GET",
  });
};

export const updateEmail = async (email: string) => {
  return Request.call({
    url: `api/applicant/step4/email`,
    method: "POST",
    data: { email },
  });
};

export const postCompanyManually = async (data: CompanyData) => {
  return Request.call({
    url: `api/applicant/step2/manual`,
    method: "POST",
    data,
  });
};

export const getProviderList = async (search: string) => {
  return Request.call({
    url: "api/applicant/step2/providers",
    method: "GET",
    params: { q: search || "a" },
  });
};

export const getCards = async () => {
  return Request.call({
    url: "api/applicant/step2/info",
    method: "GET",
  });
};
export const postIrsData = async (data: IrsForm) => {
  return Request.call({
    url: "api/irs",
    method: "POST",
    data,
  });
};
export const getIrsData = async () => {
  return Request.call({
    url: "api/irs",
    method: "GET",
  });
};

export const getPdf = async () => {
  return Request.call(
    {
      url: "api/irs/pdf",
      method: "GET",
      responseType: "blob",
    },
    true,
  );
};

export const postIrsSign = async () => {
  return Request.call({
    url: "api/irs/sign",
    method: "POST",
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postIrsSignData = async (data: any) => {
  return Request.call({
    url: "api/irs/pdf",
    method: "POST",
    data,
  });
};
