import { LoginUser } from "src/api/auth";
import {
  CandidateData,
  DisabilityInformation,
  IrsForm,
  PreEmploymentQuestionsData,
  VeteranStatus,
  VoluntaryInformation,
} from "src/api/onboarding";
import { CardDetails } from "src/components/Card/types";
import { useReducerData } from "src/store/hooks";

export type Company = {
  company_mapping_id: string;
  name: string;
  domain: string;
  confidence_level: string;
};

export type Provider = {
  id: string;
  name: string;
  data_source: string;
  is_disabled: boolean;
};

export const useAuthData = () => {
  return {
    user: {
      data: useReducerData("auth", "user.data", {}) as LoginUser,
      loading: useReducerData("auth", "user.loading", false) as boolean,
    },
    uploadResume: {
      data: useReducerData("auth", "uploadResume.data", {}) as {
        formData: FormData;
      },
      loading: useReducerData("auth", "uploadResume.loading", false) as boolean,
    },
  };
};
export const useOnboardingData = () => {
  return {
    companyList: {
      data: useReducerData("onboarding", "companyList.data", []) as Company[],
      loading: useReducerData(
        "onboarding",
        "companyList.loading",
        false,
      ) as boolean,
    },
    getBridgeToken: {
      data: useReducerData(
        "onboarding",
        "getBridgeToken.data",
        [],
      ) as Company[],
      loading: useReducerData(
        "onboarding",
        "getBridgeToken.loading",
        false,
      ) as boolean,
      error: useReducerData("onboarding", "getBridgeToken.error", "") as string,
    },
    emailList: {
      data: useReducerData("onboarding", "emailList.data", []) as string[],
      loading: useReducerData(
        "onboarding",
        "emailList.loading",
        false,
      ) as boolean,
    },
    providerList: {
      data: useReducerData("onboarding", "providerList.data", []) as Provider[],
      loading: useReducerData(
        "onboarding",
        "providerList.loading",
        false,
      ) as boolean,
    },
    candidateQuestions: {
      error: useReducerData(
        "onboarding",
        "candidateQuestions.error",
        "",
      ) as string,
      loading: useReducerData(
        "onboarding",
        "candidateQuestions.loading",
        false,
      ) as boolean,
      data: useReducerData(
        "onboarding",
        "candidateQuestions.data",
        {},
      ) as CandidateData,
    },
    voluntaryInformation: {
      error: useReducerData(
        "onboarding",
        "voluntaryInformation.error",
        "",
      ) as string,
      loading: useReducerData(
        "onboarding",
        "voluntaryInformation.loading",
        false,
      ) as boolean,
      data: useReducerData(
        "onboarding",
        "voluntaryInformation.data",
        {},
      ) as VoluntaryInformation,
    },
    disabilityInformation: {
      error: useReducerData(
        "onboarding",
        "disabilityInformation.error",
        "",
      ) as string,
      loading: useReducerData(
        "onboarding",
        "disabilityInformation.loading",
        false,
      ) as boolean,
      data: useReducerData(
        "onboarding",
        "disabilityInformation.data",
        {},
      ) as DisabilityInformation,
    },
    veteranStatus: {
      error: useReducerData("onboarding", "veteranStatus.error", "") as string,
      loading: useReducerData(
        "onboarding",
        "veteranStatus.loading",
        false,
      ) as boolean,
      data: useReducerData(
        "onboarding",
        "veteranStatus.data",
        {},
      ) as VeteranStatus,
    },
    preEmploymentQuestions: {
      error: useReducerData(
        "onboarding",
        "preEmploymentQuestions.error",
        "",
      ) as string,
      loading: useReducerData(
        "onboarding",
        "preEmploymentQuestions.loading",
        false,
      ) as boolean,
      data: useReducerData(
        "onboarding",
        "preEmploymentQuestions.data",
        {},
      ) as PreEmploymentQuestionsData,
    },
    submitApplication: {
      error: useReducerData(
        "onboarding",
        "submitApplication.error",
        "",
      ) as string,
      loading: useReducerData(
        "onboarding",
        "submitApplication.loading",
        false,
      ) as boolean,
    },
    updateEmail: {
      loading: useReducerData(
        "onboarding",
        "updateEmail.loading",
        false,
      ) as boolean,
    },
    cards: {
      data: useReducerData("onboarding", "cards.data", []) as CardDetails[],
      loading: useReducerData("onboarding", "cards.loading", false) as boolean,
    },
    irsData: {
      data: useReducerData("onboarding", "irsData.data", {}) as IrsForm,
      loading: useReducerData(
        "onboarding",
        "irsData.loading",
        false,
      ) as boolean,
    },
    getPdf: {
      loading: useReducerData("onboarding", "getPdf.loading", false) as boolean,
    },
    postIrsSign: {
      loading: useReducerData(
        "onboarding",
        "postIrsSign.loading",
        false,
      ) as boolean,
    },
  };
};

const useData = () => {
  return {
    auth: useAuthData(),
    onboarding: useOnboardingData(),
  };
};

export default useData;
