import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Logo as LogoIcon } from "src/assets/icons";
const Logo = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const url = "/onboarding/experience";

  const handleNavigate = () => {
    if (
      pathname === "/onboarding/basic-info" ||
      pathname === "/intro" ||
      pathname === "/onboarding/experience"
    ) {
      return;
    }

    navigate(url);
  };

  return <LogoIcon onClick={handleNavigate} style={{ cursor: "pointer" }} />;
};

export default Logo;
