import React, { useEffect, useMemo } from "react";
import { Routes, useLocation, useNavigate } from "react-router";
import { authRoutes } from "src/routes";
import { getRoutes } from "src/utils/getRoutes";
import { isLoggedIn } from "src/utils/helper";
import classes from "./AuthLayout.module.scss";

const AuthLayout: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAuthenticated = isLoggedIn();

  const isBasicInfoPath = useMemo(
    () => pathname === "/onboarding/basic-info",
    [pathname],
  );

  const forbidsWhen = !isAuthenticated && !isBasicInfoPath;

  useEffect(() => {
    if (forbidsWhen) {
      navigate("/onboarding/basic-info");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (forbidsWhen) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Routes>{getRoutes(authRoutes)}</Routes>
    </div>
  );
};

export default AuthLayout;
