import cs from "classnames";
import React from "react";
import { VerifiedIcon } from "src/assets/icons";
import classes from "./Badge.module.scss";
type Props = {
  children: React.ReactNode;
  type?: "success";
};
const Badge: React.FC<Props> = ({ children, type }) => {
  return (
    <div
      className={cs(classes.badge, { [classes.success]: type === "success" })}
    >
      <VerifiedIcon className={classes.verifiedIcon} />
      <p>{children}</p>
    </div>
  );
};

export default Badge;
