import * as authActions from "src/store/actions/auth";
import * as onboardingActions from "src/store/actions/onboarding";
import { useStoreActions } from "src/store/hooks";

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...onboardingActions,
  });
};
