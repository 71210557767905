/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from "classnames";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import FullPageLoader from "src/components/FullPageLoader";
import Input from "src/components/Input";
import RadioGroup from "src/components/RadioGroup";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import {
  disabilityOptions,
  disabilityPoints,
  signatureLabel,
} from "src/utils/constants";
import { formattedDate } from "src/utils/helper";
import useData from "src/utils/useData";
import classes from "./DisabilityForm.module.scss";

// eslint-disable-next-line no-restricted-imports
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  handleNext: () => void;
  buttonText: string;
  step: number;
};
type FormProps = {
  disability: string;
  pbs_name: string;
  pbs_date: string;
  pbs_signature: boolean;
};
let finishLater: boolean;

const DisabilityForm: React.FC<Props> = ({ handleNext, buttonText, step }) => {
  const { postDisabilityInformationData } = useActions();
  const {
    onboarding: {
      disabilityInformation: { loading, data },
    },
  } = useData();

  const { disability, pbs_name, pbs_date, pbs_signature } = data;

  const formSchema = Yup.object().shape({
    disability: Yup.string().required("Please select an option").trim(),
    pbs_name: Yup.string().required("This is required").trim(),
    pbs_date: Yup.string().required("Please select a date"),
    pbs_signature: Yup.boolean().oneOf([true], "You must check the checkBox"),
  });

  const handleFinishLater = async (values: FormProps) => {
    //TODO: for empty string of field ("") BackEnd throws error

    Object.keys(values).forEach((key) => {
      if (
        values[
          key as "disability" | "pbs_name" | "pbs_date" | "pbs_signature"
        ] === ""
      ) {
        delete values[
          key as "disability" | "pbs_name" | "pbs_date" | "pbs_signature"
        ];
      }
    });
    finishLater = true;

    const date = formattedDate(values.pbs_date);

    const resp: any = await postDisabilityInformationData(
      {
        ...values,
        pbs_signature: values.pbs_signature ? 1 : 0,
        finish_later: finishLater,
        pbs_date: date,
      },
      step,
    );
    if (resp?.success) handleNext();
    finishLater = false;
  };

  return (
    <div className={classes.formWrapper}>
      <Formik
        initialValues={{
          disability: disability || "",
          pbs_name: pbs_name || "",
          pbs_date: pbs_date || new Date().toISOString(),
          pbs_signature: pbs_signature === 1,
        }}
        validateOnMount
        validationSchema={formSchema}
        onSubmit={async (values: FormProps) => {
          finishLater = false;
          const date = formattedDate(values.pbs_date);
          const resp: any = await postDisabilityInformationData(
            {
              ...values,
              pbs_signature: values.pbs_signature ? 1 : 0,
              pbs_date: date,
              finish_later: finishLater,
            },
            step,
          );
          if (resp?.success) handleNext();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          isValid,
          errors,
        }: FormikProps<FormProps>) => {
          return (
            <Form>
              {loading && <FullPageLoader />}
              <Text className={classes.title}>
                Voluntary Self-Identification of Disability
              </Text>
              <Text className={classes.requiredText}>*required field.</Text>
              <div className={classes.info}>
                <div className={classes.info}>
                  <Text className={classes.infoTitle}>
                    Why are you being asked to complete this form?
                  </Text>
                  <Text>
                    We are a federal contractor or subcontractor. The law
                    requires us to provide equal employment opportunity to
                    qualified people with disabilities. We have a goal of having
                    at least 7% of our workers as people with disabilities. The
                    law says we must measure our progress towards this goal. To
                    do this, we must ask applicants and employees if they have a
                    disability or have ever had one. People can become disabled,
                    so we need to ask this question at least every five years.
                  </Text>
                  <Text className={classes.subText}>
                    Completing this form is voluntary, and we hope that you will
                    choose to do so. Your answer is confidential. No one who
                    makes hiring decisions will see it. Your decision to
                    complete the form and your answer will not harm you in any
                    way. If you want to learn more about the law or this form,
                    visit the U.S. Department of Labor’s Office of Federal
                    Contract Compliance Programs (OFCCP) website at
                    <Link
                      to="https://www.dol.gov/agencies/ofccp"
                      target="_blank"
                      className={classes.link}
                    >
                      www.dol.gov/ofccp.
                    </Link>
                  </Text>
                  <Text className={classes.infoTitle}>
                    How do you know if you have a disability?
                  </Text>
                  <Text className={classes.subText}>
                    A disability is a condition that substantially limits one or
                    more of your "major life activities." If you have or have
                    ever had such a condition, you are a person with a
                    disability.
                  </Text>
                  <Text className={classes.infoTitle}>
                    Disabilities include, but are not limited to:
                  </Text>
                  <ul>
                    {disabilityPoints.map((point, index) => (
                      <li className={classes.subPoints} key={index}>
                        {point}
                      </li>
                    ))}
                  </ul>
                  <Text className={classes.infoTitle}>
                    Please check one of the boxes below:
                  </Text>
                  <div className={classes.radioGroup}>
                    <RadioGroup
                      options={disabilityOptions}
                      selectedValue={values.disability}
                      onChange={(value) => {
                        setFieldValue("disability", value);
                      }}
                      error={errors.disability}
                      touched={touched.disability}
                      name="identification"
                      onBlur={handleBlur}
                    />
                  </div>
                  <Text className={classes.infoTitle}>
                    Public Burden Statement:
                  </Text>
                  <Text className={classes.subText}>
                    According to the Paperwork Reduction Act of 1995 no persons
                    are required to respond to a collection of information
                    unless such collection displays a valid.
                  </Text>
                  <Text className={classes.subText}>
                    OMB control number. This survey should take about 5 minutes
                    to complete.
                  </Text>
                </div>
                <div className={classes.form}>
                  <div className={classes.inputWrapper}>
                    <Input
                      placeholder="Enter Here "
                      label="Name"
                      required={true}
                      error={errors.pbs_name}
                      touched={touched.pbs_name}
                      value={values.pbs_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="pbs_name"
                      id="pbs_name"
                    />
                    <div className={classes.datePickerWrapper}>
                      <label className={classes.datePickerLabel}>
                        Today's Date*
                      </label>
                      <Field name="pbs_date">
                        {({ field, form }: FieldProps) => {
                          return (
                            <DatePicker
                              autoComplete="off"
                              placeholderText="Enter Here"
                              className={cn(classes.datePicker, {
                                [classes.isInvalid]:
                                  touched.pbs_date && errors.pbs_date,
                              })}
                              dateFormat="MMM dd, yyyy"
                              selected={
                                field.value ? new Date(field.value) : new Date()
                              }
                              onBlur={handleBlur}
                              name="pbs_date"
                              onChange={(date) => {
                                form.setFieldValue("pbs_date", date);
                              }}
                            />
                          );
                        }}
                      </Field>
                      {touched.pbs_date && errors.pbs_date && (
                        <div className={classes.invalidFeedback}>
                          {errors.pbs_date}
                        </div>
                      )}
                    </div>
                    <CheckBox
                      labelClassName={classes.checkBoxLabel}
                      checked={values.pbs_signature}
                      id="pbs_signature"
                      label={signatureLabel}
                      touched={touched.pbs_signature}
                      error={errors.pbs_signature}
                      name="pbs_signature"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <Button type="submit" disabled={!isValid || loading}>
                  {buttonText}
                </Button>
                <Button
                  variant="text"
                  type="button"
                  className={classes.finishLater}
                  onClick={() => handleFinishLater(values)}
                >
                  Finish Later
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DisabilityForm;
