/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import Button from "src/components/Button";
import Dropdown from "src/components/DropDown";
import ErrorMessage from "src/components/ErrorMessage";
import FullPageLoader from "src/components/FullPageLoader";
import Text from "src/components/Text";
import { useActions } from "src/store/actions";
import { genderOptions, raceOptions } from "src/utils/constants";
import useData from "src/utils/useData";
import classes from "./VoluntaryInformation.module.scss";

type Props = {
  handleNext: () => void;
  buttonText: string;
  step: number;
};
type FormProps = { race?: string; gender?: string };
let finishLater: boolean;

const VoluntaryInformation: React.FC<Props> = ({
  handleNext,
  buttonText,
  step,
}) => {
  const formSchema = Yup.object().shape({
    race: Yup.string().required("This field is required"),
    gender: Yup.string().required("This field is required"),
  });
  const { postVoluntaryInformationData } = useActions();
  const {
    onboarding: {
      voluntaryInformation: { error, loading, data },
    },
  } = useData();

  const { race, gender } = data;

  const handleFinishLater = async (values: FormProps) => {
    //TODO: for empty string of field ("") BackEnd throws error
    Object.keys(values).forEach((key) => {
      if (values[key as "race" | "gender"] === "") {
        delete values[key as "race" | "gender"];
      }
    });
    finishLater = true;
    const resp: any = await postVoluntaryInformationData(
      {
        ...values,
        finish_later: finishLater,
      },
      step,
    );
    if (resp?.success) handleNext();
    finishLater = false;
  };
  return (
    <div className={classes.formWrapper}>
      {loading && <FullPageLoader />}
      <div className={classes.title}>
        <Text size={24} fontWeight="bold">
          Candidate Questions:
        </Text>
        <Text>*required field.</Text>
      </div>
      <div className={classes.details}>
        <Text>
          DISH is required to compile the following information for statistical
          purposes in order to comply with certain federal regulations relating
          to Equal Employment Opportunity and Affirmative Action requirements.
        </Text>
        <Text>
          The information you provide is strictly on a voluntary basis, and the
          content of the information will not affect your eligibility for
          employment.
        </Text>
        <Text>
          Likewise, if you choose not to provide this information it will not
          affect your eligibility for employment in any way.
        </Text>
      </div>
      <Formik
        initialValues={{
          race: race || "",
          gender: gender || "",
        }}
        validateOnMount
        validationSchema={formSchema}
        onSubmit={async (values: FormProps) => {
          finishLater = false;
          const resp: any = await postVoluntaryInformationData(
            {
              ...values,
              finish_later: finishLater,
            },
            step,
          );
          if (resp?.success) handleNext();
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          isValid,
          errors,
        }: FormikProps<FormProps>) => {
          return (
            <Form>
              <div className={classes.infoSelect}>
                <Text className={classes.selectTitle}>
                  Voluntary Information
                </Text>
                <div className={classes.optionsWrapper}>
                  <Dropdown
                    label="Race"
                    options={raceOptions}
                    required={true}
                    onChange={handleChange}
                    name="race"
                    onBlur={handleBlur}
                    error={errors.race}
                    touched={touched.race}
                    value={values.race}
                  />
                  <Dropdown
                    label="Gender"
                    options={genderOptions}
                    required={true}
                    onChange={handleChange}
                    name="gender"
                    onBlur={handleBlur}
                    error={errors.gender}
                    touched={touched.gender}
                    value={values.gender}
                  />
                </div>
                {error && <ErrorMessage errorMessage={error} />}
                <div className={classes.buttonContainer}>
                  <Button type="submit" disabled={!isValid || loading}>
                    {buttonText}
                  </Button>
                  <Button
                    variant="text"
                    type="button"
                    className={classes.finishLater}
                    onClick={() => handleFinishLater(values)}
                  >
                    Finish Later
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default VoluntaryInformation;
