import Cookies from "js-cookie";

export const formattedDate = (dateValue: string) => {
  const date = new Date(dateValue);
  return date.toISOString().split("T")[0];
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const isLoggedIn = () => {
  const token = Cookies.get("token");
  return !!token;
};
export const telRegex =
  /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/ || /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

export const tinRegex = /^\d{3}-\d{2}-\d{4}$/;

export const taxpayerIdFormat = (input: string) => {
  const cleaned = ("" + input).replace(/\D/g, "");

  const formatted = cleaned.replace(/(\d{3})(\d{2})(\d{1})/, "$1-$2-$3");

  return formatted;
};
